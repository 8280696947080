import React, {FC, useState} from 'react'
import Collapsible from '../../../../common/Collapsible'
import {ErrorMessage, Field, FieldArray} from 'formik'
import {regularTransportationArr} from '../../core/ChildEnrolmentHelpers'
import {TransportationSchedule} from '../../../../common/TransportationSchedule'
import {SignatureField} from '../../../../common/SignatureField'

interface Props {
  values: any
  setFieldValue: any
}

const RegularTransportationPermission: FC<Props> = ({values, setFieldValue}) => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h3>Authorisation for regular transportation</h3>
        <div className='shadow-sm'>
          <div className='d-flex flex-column p-9'>
            {regularTransportationArr.map((regularTransportation: any, index: number) => (
              <Collapsible
                title={regularTransportation.heading}
                id={`${regularTransportation.id}-info`}
                index={index}
              >
                {regularTransportation.fields.map((field: any, index: number) => (
                  <div className='fv-row mb-5' key={index}>
                    <label className='d-flex align-items-center fs-5  mb-2'>
                      <span className='required'>{field.label}</span>
                    </label>

                    <div className='row'>
                      {field?.type === 'textarea' ? (
                        <Field
                          className='form-control form-control-solid mb-2'
                          as={field?.type}
                          rows={4}
                          placeholder={field.placeholder}
                          name={field.name}
                        />
                      ) : (
                        <Field
                          className='form-control form-control-solid mb-2'
                          type={field?.type}
                          placeholder={field.placeholder}
                          name={field.name}
                        />
                      )}
                    </div>

                    <div className='text-danger mt-2'>
                      <ErrorMessage name={field.name} />
                    </div>
                  </div>
                ))}
                <div className='fv-row mb-10'>
                  <span className='d-flex align-items-center fs-5  mb-2 row'>
                    <span className='required col'>
                      Any medical or medication requirements for child/ren?
                    </span>
                    <div className='row col'>
                      <span className='align-items-center fs-5  m-2 col'>
                        <Field
                          type='radio'
                          className='me-2 '
                          name={`regularTransportation.${regularTransportation.id}.medicationRequired`}
                          value='yes'
                        />
                        Yes
                      </span>
                      <span className='align-items-center fs-5  m-2 col'>
                        <Field
                          type='radio'
                          className='me-2'
                          name={`regularTransportation.${regularTransportation.id}.medicationRequired`}
                          value='no'
                        />
                        No
                      </span>
                    </div>
                  </span>

                  <div className='text-danger mt-2'>
                    <ErrorMessage
                      name={`regularTransportation.${regularTransportation.id}.medicationRequired`}
                    />
                  </div>
                </div>

                <FieldArray
                  name={`regularTransportation.${regularTransportation.id}.transportSchedule`}
                  render={(arrayHelpers) => (
                    <TransportationSchedule
                      values={
                        values?.regularTransportation?.[`${regularTransportation.id}`]
                          ?.transportSchedule
                      }
                      arrayHelpers={arrayHelpers}
                      field={`regularTransportation.${regularTransportation.id}.transportSchedule`}
                      setFieldValue={setFieldValue}
                    />
                  )}
                />
              </Collapsible>
            ))}
          </div>
        </div>
        <h5 className='mt-5'>Parent/Guardian:</h5>
        <div className='mb-3'>
          <span>
            I hereby give my consent for
            <Field
              type='text'
              className='w-auto form-control form-control-lg form-control-solid d-inline mx-2'
              name='educator.basicInformation.fullName'
              placeholder='Educator Name'
            />
            to pick up and drop off children from the above locations for care. Also, I give my
            consent for
            <Field
              type='text'
              className='w-auto form-control form-control-lg form-control-solid d-inline mx-2 mb-5'
              name='educator.basicInformation.fullName'
              placeholder='Name of Educator'
            />
            to provide regular transportation as detailed above mentioned locations for 12 months,
            effective from the date of this authorisation. In an emergency, I authorise the Service
            to seek necessary medical assistance from a medical practitioner or hospital including
            transportation by ambulance if required
          </span>
          <p className='mt-4'>
            Education and Care National Regulations 2011- Regulations 102B requires a transport risk
            assessment to be conducted before our service transports any child. Regulation 102D
            requires our service to receive written authorisation to transport children. Our service
            has completed a risk assessment to identify and assess any risks that the transportation
            of a child may pose to the safety, health and wellbeing of the child. This has been
            authorised by the Approved Provider and is available to sight at our service. Policies
            and procedures for transporting children are also available to view. Regular
            transportation means the transportation by the service or arranged by the service of a
            child being educated and cared for by the service, where the circumstances relevant to a
            risk assessment are substantially the same for each occasion on which the child is
            transported. An authorisation is only required once in a 12-month period.
          </p>
        </div>
      </div>
      <div className='float-end'>
        <SignatureField
          title='Parent Signature'
          field='signature'
          value={values?.signature}
          setFieldValue={setFieldValue}
          disabled={true}
        />
      </div>
    </div>
  )
}

export {RegularTransportationPermission}
