import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {useThemeMode} from '../../../_metronic/partials'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat']
const ContactLayout = () => {
  const {mode} = useThemeMode()

  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='d-flex flex-column flex-center flex-column-fluid'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          //   height: '100vh',
        }}
      >
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ContactLayout}
