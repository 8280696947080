import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {useAuth} from './core/Auth'
import {useQueryClient} from 'react-query'

export function Logout() {
  const queryClient = useQueryClient()
  const {logout} = useAuth()
  useEffect(() => {
    queryClient.clear()
    logout()
    document.location.reload()
  }, [logout])

  return (
    <Routes>
      <Navigate to='/login' />
    </Routes>
  )
}
