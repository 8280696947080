import React, {useState} from 'react'
interface Props {
  formik: any
  label: string
  field: string
  classes?: string
  labelClass?: string
}

const PasswordField: React.FC<Props> = ({formik, label, field, classes, labelClass}) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className='fv-row mb-5'>
      <div className='d-flex justify-content-between mt-n5'>
        <div className='d-flex flex-stack mb-2'>
          <label className={`form-label text-dark fs-6 mb-0 ${labelClass ? labelClass : ''}`}>
            {label}
          </label>
        </div>
      </div>

      <div className='col-lg-12 fv-row position-relative'>
        <i
          title={showPassword ? 'Hide Password' : 'Show Password'}
          onClick={togglePasswordVisibility}
          className={`bi position-absolute cursor-pointer ${
            showPassword ? 'bi-eye' : 'bi-eye-slash'
          }`}
          style={{right: '20px', top: '16px'}}
        ></i>
        <input
          placeholder='Password'
          type={showPassword ? 'text' : 'password'}
          autoComplete='off'
          {...formik.getFieldProps(field)}
          className={classes}
        />

        {formik.touched[field] && formik.errors[field] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors[field]}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export {PasswordField}
