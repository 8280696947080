import * as Yup from 'yup'
import 'yup-phone-lite'
import {ICreateEducator} from './_models'

const stringRequired = (message: string) => Yup.string().trim().required(message)

const address = Yup.object({
  streetAddress: Yup.array().of(stringRequired('Address is required')).min(1),
  suburb: stringRequired('Suburb is required'),
  postcode: stringRequired('Postcode is required'),
  region: stringRequired('Please select an option above'),
  state: stringRequired('State is required'),
  country: stringRequired('Country is required'),
})

export const createEducatorSchemas = [
  Yup.object({
    basicInformation: Yup.object({
      fullName: stringRequired('Name is required'),
      familyName: stringRequired('Family Name is required'),
      CRN: Yup.string()
        .trim()

        .required('CRN is required')
        .matches(/^[0-9]{9}[A-Z]{1}$/, 'Invalid CRN'),
      dob: Yup.date().required('Date of Birth is required'),
      // .max(new Date(Date.now() - 567648000000), 'You must be at least 18 years'),
      drivingLicense: Yup.string()

        .required('Driving License is required')
        .matches(/^[A-Z0-9]{9}$/, 'Invalid Driving license'),
      ABN: Yup.string()

        .required('ABN is required')
        .matches(/^(\d *?){11}$/, 'Invalid ABN'),
      RANumber: Yup.string()

        .required('RA Number is required')
        .matches(/^[A-Za-z0-9]{10,11}$/, 'Invalid RA'),
      email: Yup.string().required('Email is required').email(),
      contactPhone: Yup.array()
        .of(
          Yup.string()
            .phone('AU', 'Contact Number is invalid')
            .required('Contact Phone is required')
        )
        .required('Contact Phone is required'),
      dayCareAddress: address,
      isBARRAddress: Yup.boolean().required('Please select an option above'),
      bushFireRisk: Yup.boolean().required('Please select an option above'),
      dayCareAtHome: Yup.boolean().required('Please select an option above'),
      isPoolOrSpa: Yup.boolean().required('Please select an option above'),
      postalAddress: address,
      isBankRupted: Yup.boolean().required('Please select an option'),
      isCheckRefused: Yup.boolean().required('Please select an option'),
      isChargeGuilty: Yup.boolean().required('Please select an option'),
    }),
  }),
  Yup.object({
    emergencyContact: Yup.object({
      fullName: stringRequired('Name is required'),
      email: Yup.string().required().email('Email is required'),
      residentialAddress: address,
      contactPhone: Yup.array().of(
        Yup.string()

          .required('Contact Phone is required')
          .phone('AU', 'Contact Number is invalid')
      ),
      relationship: Yup.string().required('Please select relation'),
    }),
    operationDetails: Yup.object({
      // dateRegistered: Yup.date()
      //   .required('Registered date is required')
      //   .max(new Date(Date.now()), "Registered date should be on or before today's date")
      //   .nullable(true),
      // dateRegistrationCeased: Yup.date()
      //   .min(
      //     Yup.ref('dateRegistered'),
      //     "Registration ceased date can't be earlier than the date of registration."
      //   )
      //   .required('Registration ceased date is required')
      //   .nullable(true),

      workingDays: Yup.array()
        .of(Yup.string().required('Please select working day/s'))
        .min(1, 'Working days is required'),
      workingTime: stringRequired('Please select working time'),
    }),
  }),
  Yup.object({
    qualificationsCompleted: Yup.array()
      .of(
        Yup.object({
          certificate: Yup.string().required('Certificate is required'),
          completionDate: Yup.date()

            .required('Completion date is required')
            .max(new Date(Date.now()), "Completion date should be on or before today's date")
            .nullable(true),
          notes: Yup.string(),
          files: Yup.string().required('Certificate file is required'),
        })
      )
      .min(1),
    qualificationsInProgress: Yup.array()
      .of(
        Yup.object({
          certificate: Yup.string().required('Certificate is required'),
          completionDate: Yup.date()
            .required('Completion date is required')
            .min(new Date(Date.now() + 86400), "Completion date should be after today's date.")
            .nullable(true),

          notes: Yup.string(),
          files: Yup.string().required('Certificate file is required'),
        })
      )
      .min(0),
  }),

  Yup.object({
    trainingsCompleted: Yup.array()
      .of(
        Yup.object({
          certificate: Yup.string().required('Certificate is required'),
          completionDate: Yup.date().required('Completion date is required'),
          expirationDate: Yup.date()
            .min(Yup.ref('completionDate'), "Expiration date can't be lesser than completion date")

            .required('Expiration date is required')
            .nullable(true),
          notes: Yup.string(),
          files: Yup.string().required('Certificate file is required'),
        })
      )
      .min(1),
  }),

  Yup.object({
    securityDetails: Yup.object({
      children: Yup.array()
        .of(
          Yup.object({
            fullName: stringRequired('Full name is required'),
            dob: Yup.date()
              .required('Date of birth is required')
              .max(new Date(Date.now()), "Date of birth should be on or before today's date")
              .nullable(true),
            policeCheckReport: Yup.string().required('Police check report is required'),
          })
        )
        .min(1),
      adults: Yup.array()
        .of(
          Yup.object({
            fullName: Yup.string().required('Full name is required'),
            dob: Yup.date()
              .required('Date of birth is required')
              .nullable(true)
              .max(new Date(Date.now() - 567648000000), 'He / She must be at least 18 years'),
            policeCheckReport: stringRequired('Police check report is required'),
            identificationNumber: stringRequired('Identification Number is required'),
            // .matches(/^\d{3}\s\d{3}\s\d{3}$/, 'Invalid Identification number'),
            expiryDate: Yup.date().required('Expiry date is required').nullable(true),
            workingWithChildrenCheck: stringRequired('Working with children check is required'),
          })
        )
        .min(1),
    }),
  }),
  Yup.object({
    statutoryDeclarationForm: stringRequired('Please upload the filled form'),
  }),
  Yup.object({
    medicalCertificate: stringRequired('Please upload the filled form'),
  }),
]

export const inits: ICreateEducator = [
  {
    basicInformation: {
      fullName: '',
      familyName: '',
      CRN: '',
      RANumber: '',
      dob: '',
      drivingLicense: '',
      ABN: '',
      email: '',
      sameAddress: false,
      contactPhone: [''],
      dayCareAddress: {
        streetAddress: [''],
        suburb: '',
        postcode: '',
        region: '',
        state: '',
        country: 'Australia',
      },
      isBARRAddress: '',
      bushFireRisk: '',
      dayCareAtHome: '',
      isPoolOrSpa: '',
      postalAddress: {
        streetAddress: [''],
        suburb: '',
        postcode: '',
        region: '',
        state: '',
        country: 'Australia',
      },
      isBankRupted: '',
      isCheckRefused: '',
      isChargeGuilty: '',
    },
  },
  {
    emergencyContact: {
      fullName: '',
      email: '',
      contactPhone: [''],
      residentialAddress: {
        streetAddress: [''],
        suburb: '',
        postcode: '',
        region: '',
        state: '',
        country: 'Australia',
      },
      relationship: '',
    },
    operationDetails: {
      dateRegistered: '',
      dateRegistrationCeased: '',
      workingDays: [],
      workingTime: '',
    },
  },
  {
    qualificationsCompleted: [
      {
        certificate: '',
        completionDate: '',
        notes: '',
        files: '',
      },
    ],
    qualificationsInProgress: [],
  },
  {
    trainingsCompleted: [
      {
        certificate: 'First Aid',
        completionDate: '',
        expirationDate: '',
        notes: '',
        files: '',
      },
      {
        certificate: 'CPR',
        completionDate: '',
        expirationDate: '',
        notes: '',
        files: '',
      },
      {
        certificate: 'Advanced Diploma in Children’s Services',
        completionDate: '',
        expirationDate: '',
        notes: '',
        files: '',
      },
      {
        certificate: 'Anaphylaxis Management',
        completionDate: '',
        expirationDate: '',
        notes: '',
        files: '',
      },
      {
        certificate: 'Asthma Management',
        completionDate: '',
        expirationDate: '',
        notes: '',
        files: '',
      },
    ],
  },
  {
    securityDetails: {
      children: [
        {
          fullName: '',
          dob: '',
          policeCheckReport: '',
        },
      ],
      adults: [
        {
          fullName: '',
          dob: '',
          policeCheckReport: '',
          identificationNumber: '',
          expiryDate: '',
          workingWithChildrenCheck: '',
        },
      ],
    },
  },
  {
    statutoryDeclarationForm: '',
  },
  {
    medicalCertificate: '',
  },
]

export const educatorKeysData: {keys: string[]}[] = [
  {
    keys: ['basicInformation'],
  },
  {
    keys: ['emergencyContact', 'operationDetails'],
  },
  {
    keys: ['qualificationsCompleted', 'qualificationsInProgress'],
  },
  {
    keys: ['trainingsCompleted'],
  },
  {
    keys: ['securityDetails'],
  },
  {
    keys: ['statutoryDeclarationForm'],
  },
  {
    keys: ['medicalCertificate'],
  },
]

export const days = [
  {
    value: 'Monday',
    label: 'Monday',
  },
  {
    value: 'Tuesday',
    label: 'Tuesday',
  },
  {
    value: 'Wednesday',
    label: 'Wednesday',
  },
  {
    value: 'Thursday',
    label: 'Thursday',
  },
  {
    value: 'Friday',
    label: 'Friday',
  },
  {
    value: 'Saturday',
    label: 'Saturday',
  },
]

export const certifications = [
  {
    label: 'Certificate III in Children’s Services',
    value: 'Certificate III in Children’s Services',
  },
  {label: 'Diploma in Children’s Services', value: 'Diploma in Children’s Services'},
  {
    label: 'Advanced Diploma in Children’s Services',
    value: 'Advanced Diploma in Children’s Services',
  },
  {label: 'Bachelor of Education', value: 'Bachelor of Education'},
  {
    label: 'Other relevant qualification, please give',
    value: 'Other relevant qualification, please give',
  },
]

export const mandatoryTraining = [
  {
    label: 'First Aid',
    value: 'First Aid',
  },
  {label: 'CPR', value: 'CPR'},
  {
    label: 'Advanced Diploma in Children’s Services',
    value: 'Advanced Diploma in Children’s Services',
  },
  {label: 'Anaphylaxis Management', value: 'Anaphylaxis Management'},
  {
    label: 'Asthma Management',
    value: 'Asthma Management',
  },
]

export const EducatorSteps = [
  {
    title: 'Application Forms',
    description: 'Setup your account details',
    stepValue: '1',
  },
  {
    title: 'Emergency Contact & Operations',
    description: 'Your emergency contact details',
    stepValue: '2',
  },
  {
    title: 'Educator Qualification',
    description: 'Your certifications details',
    stepValue: '3',
  },
  {
    title: 'Training',
    description: 'Your training details',
    stepValue: '4',
  },
  {
    title: 'Security Check',
    description: 'Enter your security details',
    stepValue: '5',
  },
  {
    title: 'Statutory Declaration',
    description: 'Statutory Declarations Act',
    stepValue: '6',
  },
  {
    title: 'Medical Certificate',
    description: 'Your medical certification details',
    stepValue: '7',
  },
  {
    title: 'Completed',
    description: 'Woah, we are here',
    stepValue: '8',
  },
]

export const regions = ['Area 1', 'Area 2', 'Area 3', 'Area 4', 'Area 5']
