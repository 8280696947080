/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {StepperComponent} from '../../../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {
  childPostApprovalKeyData,
  createEducatorSchemas,
  inits,
  mergeDeep,
} from '../core/ChildEnrolmentHelpers'
import {useMutation, useQuery} from 'react-query'
import {createChild, getChildByID, updateChild} from '../core/_request'
import {useParams} from 'react-router-dom'
import {ICreateEducator} from '../core/_models'

import {useAuth} from '../../../../auth'
import {StepperAsideMenu} from '../../../common/StepperAsideMenu'
import {ParentEducatorAgreementPostForm} from './steps/ParentEducatorAgreementPostForm'
import {EducatorFeeSchedule} from './steps/EducatorFeeSchedule'
import {ChildPostApprovalSteps} from '../../child-form/core/ChildEnrolmentHelpers'
import {ComplianceWrittenAgreement} from './steps/ComplianceWrittenAgreement'
import {StatutoryDeclarationForm} from './steps/StatutoryDeclarationForm'
import {SaturdayStatutoryDeclaration} from './steps/SaturdayStatutoryDeclaration'
import {ThankYouPage} from '../../../common/ThankYou'
import {RegularTransportationPermission} from './steps/RegularTransportationPermission'
import {FocusError} from '../../../common/FocusError'

export const ChildPostApprovalForm = () => {
  const {id} = useParams()
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const query: any = useQuery(['childData', id], () => getChildByID(id as string), {
    enabled: !!id, // Only execute the query if the ID is defined and not null
  })

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper: any = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createEducatorSchemas[0])
  const [initValues, setInitValues] = useState<any>(inits[0])
  const [educatorFormValues, setEducatorFormValues] = useState<ICreateEducator>()
  const createMutation: any = useMutation(createChild)
  const updateMutation: any = useMutation(updateChild)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    })
  }, [stepper?.current?.currentStepIndex])

  useEffect(() => {
    if (!query.isLoading && query?.isSuccess) {
      setEducatorFormValues(query?.data)
      // setInitValues({basicInformation: query?.data?.basicInformation})
    }
  }, [query.isLoading])

  const updateStepperWithData = () => {
    setCurrentSchema(createEducatorSchemas[stepper.current.currentStepIndex])
    setInitValues(inits[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper?.current?.goNext()
    } else {
      stepper.current.goto(1)
      // actions.resetForm()
    }
    if (!educatorFormValues?._id) {
      navigate(`/child/edit/${createMutation?.data?._id}`)
      createMutation?.data && setEducatorFormValues(createMutation?.data)
    } else {
      updateMutation?.data && setEducatorFormValues(updateMutation?.data)
    }
  }

  useEffect(() => {
    if (!updateMutation?.isLoading && updateMutation?.isSuccess) {
      updateStepperWithData()
    }
  }, [updateMutation?.isSuccess])

  useEffect(() => {
    if (!createMutation?.isLoading && createMutation?.isSuccess) {
      updateStepperWithData()
    }
  }, [createMutation?.isSuccess])

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createEducatorSchemas[stepper?.current?.currentStepIndex - 1])
    setInitValues(inits[stepper?.current?.currentStepIndex - 1])
  }

  const submitStep = async (values: any, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    // if (stepper.current.currentStepIndex + 1 === stepper.current.totatStepsNumber) {
    //   values.status = 'Pending'
    // }
    const formDataValues: any = {}
    childPostApprovalKeyData[stepper?.current?.currentStepIndex - 1]['keys'].map((key: string) => {
      formDataValues[key] = values[key]
    })

    values._id
      ? await updateMutation.mutate({
          values: {...formDataValues, email: currentUser?.email},
          _id: values._id,
        })
      : await createMutation.mutate({...formDataValues, email: currentUser?.email})
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      <StepperAsideMenu steps={ChildPostApprovalSteps} />

      <div className='d-flex flex-row-fluid bg-body rounded'>
        <Formik
          validationSchema={currentSchema}
          enableReinitialize
          initialValues={mergeDeep(initValues, educatorFormValues)}
          onSubmit={submitStep}
        >
          {({values, errors, setFieldValue}) => (
            <Form className='py-20 w-100 px-9' noValidate id='kt_create_account_form'>
              {createMutation.isError && (
                <div>
                  <div className='alert alert-danger'>
                    {createMutation.error?.response?.data?.message ||
                      createMutation.error?.response?.data?.message[0] ||
                      ''}
                  </div>
                </div>
              )}
              <div className='current' data-kt-stepper-element='content'>
                {stepper?.current?.currentStepIndex === 1 && (
                  <ParentEducatorAgreementPostForm values={values} setFieldValue={setFieldValue} />
                )}
              </div>
              <div data-kt-stepper-element='content'>
                {stepper?.current?.currentStepIndex === 2 && (
                  <fieldset disabled>
                    <EducatorFeeSchedule values={values} setFieldValue={setFieldValue} />
                  </fieldset>
                )}
              </div>
              <div data-kt-stepper-element='content'>
                {stepper?.current?.currentStepIndex === 3 && (
                  <ComplianceWrittenAgreement values={values} setFieldValue={setFieldValue} />
                )}
              </div>
              <div data-kt-stepper-element='content'>
                {stepper?.current?.currentStepIndex === 4 && (
                  <StatutoryDeclarationForm values={values} setFieldValue={setFieldValue} />
                )}
              </div>
              <div data-kt-stepper-element='content'>
                {stepper?.current?.currentStepIndex === 5 && (
                  <SaturdayStatutoryDeclaration values={values} setFieldValue={setFieldValue} />
                )}
              </div>
              <div data-kt-stepper-element='content'>
                {stepper?.current?.currentStepIndex === 6 && (
                  <RegularTransportationPermission values={values} setFieldValue={setFieldValue} />
                )}
              </div>
              <div data-kt-stepper-element='content'>
                {stepper?.current?.currentStepIndex === 7 && <ThankYouPage />}
              </div>
              <FocusError />

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className={`btn btn-lg btn-light-primary me-3 ${
                      stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber
                        ? 'd-none'
                        : ''
                    }`}
                    data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    Back
                  </button>
                </div>

                <div>
                  <button
                    type='submit'
                    className={`btn btn-lg btn-primary me-3 ${
                      stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber
                        ? 'd-none'
                        : ''
                    }`}
                  >
                    <span className='indicator-label'>
                      {stepper.current?.currentStepIndex !==
                        stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                      {stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber! - 1 && 'Got It'}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
