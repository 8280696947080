/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import Select from 'react-select'
import {useAuth} from '../../../../../auth'
import Collapsible from '../../../../common/Collapsible'
import ChildHeader from '../../../../common/ChildFormHeader'
import {countriesList, languagesSpoken} from '../../core/ChildEnrolmentHelpers'
import {AddressDetailInfo} from '../../../../common/AddressDetailInfo'
import DatePicker from '../../../../common/DatePicker'

const ChildInformationForm: FC<any> = ({values, setFieldValue}) => {
  const {currentUser} = useAuth()

  return (
    <div className='w-100'>
      <ChildHeader />
      <div className='pb-10 pb-lg-15'>
        <div className='shadow-sm'>
          <div className='d-flex flex-column p-9'>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5  mb-2'>
                <span className='required'>Priority:</span>
              </label>
              <div className='row'>
                <span className='align-items-center fs-5  m-2 col'>
                  <Field
                    type='radio'
                    className='me-2'
                    name='child.priority'
                    value='Risk of abuse/ Neglect'
                  />
                  Risk of abuse/ Neglect
                </span>
                <span className='align-items-center fs-5  m-2 col'>
                  <Field
                    type='radio'
                    className='me-2'
                    name='child.priority'
                    value='Parents working/studying'
                  />
                  Parents working/studying
                </span>

                <span className='align-items-center fs-5  m-2 col'>
                  <Field type='radio' className='me-2' name='child.priority' value='other' />
                  Other
                </span>
              </div>

              <div className='text-danger mt-2'>
                <ErrorMessage name='child.priority' />
              </div>
            </div>
            <Collapsible title={'Personal info'} id='personal-info-11'>
              <div className='fv-row'>
                <div className='row'>
                  <div className='col-md-6 fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Family name of child</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='child.familyName'
                      placeholder='Family name'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='child.familyName' />
                    </div>
                  </div>
                  <div className='col-md-6 fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Given name of child</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='child.fullName'
                      placeholder='Given name'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='child.fullName' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>Child's gender</span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field type='radio' className='me-2' name='child.gender' value='Male' />
                    Male
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4 '>
                    <Field type='radio' className='me-2' name='child.gender' value='Female' />
                    Female
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.gender' />
                </div>
              </div>

              <div className='fv-row'>
                <div className='row'>
                  <div className='col-md-6 fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Date of birth</span>
                    </label>

                    <DatePicker
                      name='child.dob'
                      selected={(values?.child?.dob && new Date(values?.child?.dob)) || null}
                      placeholderText='Date of birth'
                      onChange={(value: any) => setFieldValue('child.dob', value)}
                    />

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='child.dob' />
                    </div>
                  </div>
                  <div className='col-md-6 fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>CRN number</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Centerlink Customer Reference Number'
                      ></i>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='child.CRN'
                      placeholder='xxxxxxxxxx'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='child.CRN' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='fv-row'>
                <div className='row'>
                  <div className='col-md-6 fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Medicare number</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='child.medicareNumber'
                      placeholder='xxxxxx'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='child.medicareNumber' />
                    </div>
                  </div>
                  <div className='col-md-6 fv-row mb-5'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Language spoken at home</span>
                    </label>

                    <Select
                      options={languagesSpoken}
                      placeholder='Select'
                      name='child.homeSpokenLanguage'
                      className='multi-select-container mt-3'
                      classNamePrefix='multi-select'
                      onChange={(e: any) => {
                        setFieldValue('child.homeSpokenLanguage', e.value)
                      }}
                      value={languagesSpoken.find(
                        (language: any) => values?.child?.homeSpokenLanguage === language.value
                      )}
                      closeMenuOnSelect={true}
                    />

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='child.homeSpokenLanguage' />
                    </div>
                  </div>
                </div>
              </div>
            </Collapsible>

            <Collapsible title='Address of the child residence' id='child-residence'>
              <AddressDetailInfo
                title={'Home address'}
                values={values}
                setFieldValue={setFieldValue}
                field='child.address'
              />
            </Collapsible>

            <Collapsible title="Child's background">
              <div className='row fv-row  mb-10'>
                <label className='d-flex fs-5 fw-bold mb-2'>
                  <span className='required'>Child’s cultural background</span>
                </label>

                <Field
                  className='form-control form-control-solid mb-2'
                  as='textarea'
                  rows={4}
                  placeholder='Enter the cultural details concerning to your child'
                  name='child.culturalBackground'
                ></Field>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.culturalBackground' />
                </div>
              </div>
              <div className='row fv-row  mb-10'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span className='required'>Country of birth</span>
                </label>
                <Select
                  options={countriesList}
                  placeholder='Select'
                  name='child.countryOfBirth'
                  className='multi-select-container mt-3'
                  classNamePrefix='multi-select'
                  onChange={(e: any) => {
                    setFieldValue('child.countryOfBirth', e.value)
                  }}
                  value={countriesList.find(
                    (country: {value: string}) => values?.child?.countryOfBirth === country.value
                  )}
                  closeMenuOnSelect={true}
                />

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.countryOfBirth' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5  mb-2'>
                  <span className='required'>
                    Is the child of aboriginal or torres strait islander background?
                  </span>
                </label>
                <div className='row'>
                  <span className='align-items-center fs-5  m-2 col-4'>
                    <Field type='radio' className='me-2' name='child.isAboriginal' value='true' />
                    Yes
                  </span>
                  <span className='align-items-center fs-5  m-2 col-4'>
                    <Field type='radio' className='me-2' name='child.isAboriginal' value='false' />
                    No
                  </span>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.isAboriginal' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='required col-10'>Does the child have developmental delay?</span>
                  <div className='row col-2'>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2 '
                        name='child.hasDevelopmentDelay'
                        value='true'
                      />
                      Yes
                    </span>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2'
                        name='child.hasDevelopmentDelay'
                        value='false'
                      />
                      No
                    </span>
                  </div>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.hasDevelopmentDelay' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='required col-10'>
                    Does the child have intellectual disability or impairment?
                  </span>
                  <div className='row col-2'>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2 '
                        name='child.hasIntellectualDisability'
                        value='true'
                      />
                      Yes
                    </span>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2'
                        name='child.hasIntellectualDisability'
                        value='false'
                      />
                      No
                    </span>
                  </div>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.hasIntellectualDisability' />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='required col-10'>
                    Does the child have physical or sensory disability or impairment?
                  </span>
                  <div className='row col-2'>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2 '
                        name='child.hasSensoryDisability'
                        value='true'
                      />
                      Yes
                    </span>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2'
                        name='child.hasSensoryDisability'
                        value='false'
                      />
                      No
                    </span>
                  </div>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.hasSensoryDisability' />
                </div>
              </div>
            </Collapsible>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ChildInformationForm}
