import * as Yup from 'yup'
import 'yup-phone-lite'

const requiredAddress = Yup.object({
  streetAddress: Yup.array().of(Yup.string().trim().required('Address is required')).min(1),
  suburb: Yup.string().trim().label('Suburb').required('Suburb is required'),
  postcode: Yup.string().trim().label('postcode').required('Postcode is required'),
  region: Yup.string().required('Please select an option above'),
  state: Yup.string().trim().label('State').required('State is required'),
  country: Yup.string().trim().label('Country').required('Country is required'),
})

const notRequiredAddress = Yup.object().shape({
  streetAddress: Yup.array().of(Yup.string().label('Address')),
  suburb: Yup.string().label('Suburb'),
  postcode: Yup.string().label('postcode'),
  region: Yup.string(),
  state: Yup.string().label('State'),
  country: Yup.string().label('Country'),
})

export const createEducatorSchemas = [
  Yup.object({
    signature: Yup.string().required('Signature is required').nullable(true),
    child: Yup.object({
      priority: Yup.string().required('Please select an option'),
      familyName: Yup.string()
        .trim()
        .required('Family name is required')
        .min(3, 'Family Name must be at least 3 characters'),
      fullName: Yup.string()
        .trim()
        .required('Full name is required')
        .min(3, 'Full name must be at least 3 characters'),
      gender: Yup.string().required('Please select an option'),
      dob: Yup.string().required('Date of birth is required').nullable(true),
      CRN: Yup.string()
        .trim()
        .label('CRN')
        .required('CRN is required')
        .matches(/^[0-9]{9}[A-Z]{1}$/, 'Invalid CRN'),
      medicareNumber: Yup.string()
        .trim()
        .required('Medicare number is required')
        .matches(/^(?=.{9,12}$).*$/, 'Invalid medicare number'),
      homeSpokenLanguage: Yup.string().required('Language is required'),
      address: requiredAddress,
      culturalBackground: Yup.string().trim().required('Cultural background is required'),
      countryOfBirth: Yup.string().trim().required('Country of Birth is required'),
      isAboriginal: Yup.string().required('Please select an option'),
      hasDevelopmentDelay: Yup.string().required('Please select an option'),
      hasIntellectualDisability: Yup.string().required('Please select an option'),
      hasSensoryDisability: Yup.string().required('Please select an option'),
    }),
  }),
  Yup.object({
    signature: Yup.string().required('Signature is required').nullable(),
    guardian: Yup.object({
      responsible: Yup.string().required('Please select guardian'),
      centrelinkRecord: Yup.string().required('Centrelink is required'),
    }),
    specialConsiderations: Yup.object({
      childLiveWithMother: Yup.string().trim().required('Description is required'),
      religiousInfo: Yup.string().trim().required('Description is required'),
      dietaryInfo: Yup.string().trim().required('Description is required'),
      otherRequirements: Yup.string().trim().required('Description is required'),
    }),
    courtOrders: Yup.object({
      anyOrder: Yup.string().required('Please select an option'),
      courtOrderFile: Yup.string().when(['anyOrder'], {
        is: (value: string) => value === 'yes',
        then: Yup.string().required('Please upload file'),
      }),
    }),
    mother: Yup.object().when(['guardian.responsible'], {
      is: (value: string) => value === 'mother' || value === 'both',
      then: Yup.object().shape({
        fullName: Yup.string()
          .trim()
          .required('Full Name is required')
          .min(3, 'Full Name must be at least 3 characters'),
        dob: Yup.date()
          .label('Date of birth')
          .required('Date of birth is required')
          .max(new Date(Date.now() - 567648000000), 'You must be at least 18 years')
          .nullable(true),
        address: requiredAddress,
        homeContact: Yup.string().phone('AU', 'Contact number is invalid'),
        mobileContact: Yup.string()
          .required('Mobile Phone is required')
          .phone('AU', 'Contact number is invalid'),
        email: Yup.string().label('Email').required('Email is required').email(),

        languageSpoken: Yup.string().required('Language is required'),
        culturalBackground: Yup.string().trim().required('Cultural background is required'),
        job: Yup.string().required('Job is required'),
        birthPlace: Yup.string().required('Birth place is required'),
        CRN: Yup.string()
          .trim()
          .label('CRN')
          .required('CRN is required')
          .matches(/^[0-9]{9}[A-Z]{1}$/, 'Invalid CRN'),
        medicareNumber: Yup.string()
          .trim()
          .required('Medicare Number is required')
          .matches(/^(?=.{9,12}$).*$/, 'Invalid medicare number'),
        childLiveWithMother: Yup.string().required('Please select an option'),
      }),
    }),
    father: Yup.object().when(['guardian.responsible'], {
      is: (value: string) => value === 'father' || value === 'both',
      then: Yup.object().shape({
        fullName: Yup.string()
          .trim()
          .required('Full name is required')
          .min(3, 'Full name must be at least 3 characters'),
        dob: Yup.date()
          .label('Date of birth')
          .required('Date of birth is required')
          .max(new Date(Date.now() - 567648000000), 'You must be at least 18 years')
          .nullable(true),
        address: requiredAddress,
        homeContact: Yup.string().phone('AU', 'Contact number is invalid'),
        mobileContact: Yup.string()
          .required('Mobile phone is required')
          .phone('AU', 'Contact number is invalid'),
        email: Yup.string().label('Email').required('Email is required').email(),

        languageSpoken: Yup.string().required('Language is required'),
        culturalBackground: Yup.string().required('Cultural background is required'),
        job: Yup.string().required('Job is required'),
        birthPlace: Yup.string().required('Birth place is required'),
        CRN: Yup.string()
          .trim()
          .label('CRN')
          .required('CRN is required')
          .matches(/^[0-9]{9}[A-Z]{1}$/, 'Invalid CRN'),
        medicareNumber: Yup.string()
          .trim()
          .required('Medicare number is required')
          .matches(/^(?=.{9,12}$).*$/, 'Invalid medicare number'),
        childLiveWithMother: Yup.string().required('Please select an option'),
      }),
    }),
  }),
  Yup.object({
    signature: Yup.string().trim().required('Signature is required').nullable(),
    nominees: Yup.array()
      .of(
        Yup.object({
          fullName: Yup.string()
            .trim()
            .required('Full name is required')
            .min(3, 'Full name must be at least 3 characters'),
          relationWithChild: Yup.string().required('Please select option'),
          address: requiredAddress,
          homeContact: Yup.string().phone('AU', 'Contact number is invalid'),
          mobileContact: Yup.string()
            .required('Mobile phone is required')
            .phone('AU', 'Contact number is invalid'),
          email: Yup.string().label('Email').required('Email is required').email(),
          otherContact: Yup.string(),
        })
      )
      .min(2, 'Child must have at least 2 nominees'),
  }),

  Yup.object({
    signature: Yup.string().required('Signature is required').nullable(),
    child: Yup.object({
      ambulanceSubscription: Yup.string().trim().required('Please select an option'),
      medicareCard: Yup.string().required('Please upload file'),
      doctor: Yup.object({
        fullName: Yup.string()
          .trim()
          .required('Full name is required')
          .min(3, 'Full name must be at least 3 characters'),
        medicalService: Yup.object({
          contact: Yup.string()
            .required('Medical service contact is required')
            .phone('AU', 'Contact number is invalid'),
          name: Yup.string().trim().required('Medical service name is required'),
        }),
        address: requiredAddress,
      }),
      medicalCare: Yup.object({
        healthCareNeed: Yup.object({
          status: Yup.string().required('Please select an option'),
        }),
        allergy: Yup.object({
          status: Yup.string().required('Please select an option'),
        }),
        anaphylaxis: Yup.object({
          status: Yup.string().required('Please select an option'),
        }),
        NDISEnrolled: Yup.object({
          status: Yup.string().required('Please select an option'),
        }),
      }),
      allergyOrSensitivity: Yup.object({
        status: Yup.string().required('Please select an option'),
        file: Yup.string().when(['status'], {
          is: (status: string) => status === 'yes',
          then: Yup.string().required('Please upload file'),
        }),
      }),
      hasEpiPen: Yup.string().required('Please select an option'),
      anaphylaxisManagement: Yup.string().required('Please select an option'),
      riskManagement: Yup.string().required('Please select an option'),
      diagnosedAsthma: Yup.object({
        status: Yup.string().required('Please select an option'),
        file: Yup.string().when(['status'], {
          is: (status: string) => status === 'yes',
          then: Yup.string().required('Please upload file'),
        }),
      }),
      asthmaManagement: Yup.string().required('Please select an option'),
      asthmaRiskManagement: Yup.string().required('Please select an option'),
      anaphylaxisAwareness: Yup.boolean().oneOf([true], 'Please select the checkbox'),
      policyOfAnaphylaxis: Yup.boolean().oneOf([true], 'Please select the checkbox'),
      hasChildMedicalManagement: Yup.boolean().oneOf([true], 'Please select the checkbox'),
    }),
  }),

  Yup.object({
    signature: Yup.string().required('Signature is required').nullable(),
    governmentAssistance: Yup.object({
      claimCCS: Yup.string().required('Please select an option'),
      CCSConfirmation: Yup.string().required('Please select an option'),
    }),
    childImmunised: Yup.string().required('Please select an option'),
    childImmunisedFile: Yup.string().when(['childImmunised'], {
      is: (childImmunised: string) => childImmunised === 'yes',
      then: Yup.string().required('Please upload file'),
    }),
    immunisationUpToDate: Yup.string().required('Please select an option'),
    hasOtherService: Yup.string().required('Please select an option'),
    otherServiceNotice: Yup.object().when(['hasOtherService'], {
      is: (hasOtherService: string) => hasOtherService === 'yes',
      then: Yup.object().shape({
        startDate: Yup.string().required('Start date is required').nullable(true),
        lastDate: Yup.string().required('Last date is required').nullable(true),
      }),
    }),
    childSchool: Yup.object({
      goingToSchool: Yup.string().required('Please select an option'),
      name: Yup.string().when(['goingToSchool'], {
        is: (goingToSchool: string) => goingToSchool === 'yes',
        then: Yup.string().trim().required('School name is required'),
        otherwise: Yup.string().notRequired(),
      }),
      address: Yup.object().when(['goingToSchool'], {
        is: (goingToSchool: string) => goingToSchool === 'yes',
        then: requiredAddress,
        otherwise: notRequiredAddress,
      }),
    }),
  }),

  Yup.object({
    signature: Yup.string().required('Signature is required').nullable(),
    permission: Yup.object({
      medicalTreatment: Yup.string().required('Please select an option'),
      ambulanceService: Yup.string().required('Please select an option'),
      canTravelOnEducatorCar: Yup.string().required('Please select an option'),
      canTravelOnPublicTransport: Yup.string().required('Please select an option'),
      sunscreenAllow: Yup.string().required('Please select an option'),
      allowSanitizer: Yup.string().required('Please select an option'),
      photographForMe2FDC: Yup.string().required('Please select an option'),
      photographForReportage: Yup.string().required('Please select an option'),
      photographForPosting: Yup.string().required('Please select an option'),
    }),
  }),

  Yup.object({
    signature: Yup.string().required('Signature is required').nullable(),
    policyAgreement: Yup.boolean().oneOf([true], 'Please select the checkbox'),
  }),

  Yup.object({
    signature: Yup.string().required('Signature is required').nullable(),
    childSchool: Yup.object({
      attends: Yup.string().required('Please select an option'),
      name: Yup.string().when(['attends'], {
        is: (attends: string) => attends !== 'none',
        then: Yup.string().trim().required('School name is required'),
        otherwise: Yup.string().notRequired().nullable(true),
      }),
      startsAt: Yup.string().when(['attends'], {
        is: (attends: string) => attends !== 'none',
        then: Yup.string().trim().required('School start time is required').nullable(true),
        otherwise: Yup.string().notRequired().nullable(true),
      }),
      endsAt: Yup.string().when(['attends'], {
        is: (attends: string) => attends !== 'none',
        then: Yup.string().required('School end time is required').nullable(true),
        otherwise: Yup.string().notRequired().nullable(true),
      }),
      termsDate: Yup.array().when(['attends'], {
        is: (attends: string) => attends !== 'none',
        then: Yup.array().of(Yup.string().required('Term month is required').nullable(true)),
        otherwise: Yup.array(),
      }),

      address: Yup.object().when(['attends'], {
        is: (attends: string) => attends !== 'none',
        then: requiredAddress,
        otherwise: notRequiredAddress,
      }),
    }),
    childAssistance: Yup.object().when(['childSchool.attends'], {
      is: (attends: string) => attends && attends !== 'none',
      then: Yup.object().shape({
        afterSchool: Yup.string().required('Assistance after school is required').nullable(true),
        beforeSchool: Yup.string().required('Assistance before school is required').nullable(true),
      }),
      otherwise: Yup.object().shape({
        afterSchool: Yup.string().nullable(true),
        beforeSchool: Yup.string().nullable(true),
      }),
    }),
  }),

  Yup.object({
    signature: Yup.string().required('Signature is required').nullable(),
    childSchedule: Yup.object({
      schoolSchedule: Yup.array()
        .of(
          Yup.object({
            day: Yup.string().required('Please select day'),
            beforeSchoolCareTime: Yup.string().required('Please select time').nullable(true),
            afterSchoolCareTime: Yup.string().required('Please select time').nullable(true),
          })
        )
        .min(1),
      holidaySchedule: Yup.array()
        .of(
          Yup.object({
            day: Yup.string().required('Please select day'),
            careTime: Yup.string().required('Please select time').nullable(true),
          })
        )
        .min(1),
    }),
  }),
  Yup.object({
    signature: Yup.string().required('Signature is required').nullable(),
    child: Yup.object({
      hasRelationWithEducator: Yup.string().required('Please select an option'),
      relationWithEducator: Yup.string().when(['hasRelationWithEducator'], {
        is: (hasRelationWithEducator: string) => hasRelationWithEducator === 'yes',
        then: Yup.string().required('Please select relation'),
        otherwise: Yup.string().notRequired(),
      }),
    }),
  }),
]

export const inits: any = [
  {
    signature: '',
    child: {
      priority: '',
      familyName: '',
      fullName: '',
      gender: '',
      dob: '',
      CRN: '',
      medicareNumber: '',
      homeSpokenLanguage: '',
      culturalBackground: '',
      countryOfBirth: '',
      isAboriginal: '',
      hasDevelopmentDelay: '',
      hasIntellectualDisability: '',
      hasSensoryDisability: '',
      address: {
        streetAddress: [''],
        suburb: '',
        postcode: '',
        region: '',
        state: '',
        country: 'Australia',
      },
    },
  },
  {
    guardian: {
      responsible: '',
      centrelinkRecord: '',
    },
    courtOrders: {
      anyOrder: '',
      courtOrderFile: '',
    },
    specialConsiderations: {
      childLiveWithMother: '',
      religiousInfo: '',
      dietaryInfo: '',
      otherRequirements: '',
    },
    mother: {
      fullName: '',
      dob: '',
      address: {
        streetAddress: [''],
        suburb: '',
        postcode: '',
        region: '',
        state: '',
        country: 'Australia',
      },
      homeContact: '',
      mobileContact: '',
      email: '',
      languageSpoken: '',
      culturalBackground: '',
      job: '',
      birthPlace: '',
      CRN: '',
      medicareNumber: '',
      childLiveWithMother: '',
    },
    father: {
      fullName: '',
      dob: '',
      address: {
        streetAddress: [''],
        suburb: '',
        postcode: '',
        region: '',
        state: '',
        country: 'Australia',
      },
      homeContact: '',
      mobileContact: '',
      email: '',
      languageSpoken: '',
      culturalBackground: '',
      job: '',
      birthPlace: '',
      CRN: '',
      medicareNumber: '',
      childLiveWithMother: '',
    },
  },
  {
    nominees: [
      {
        fullName: '',
        relationWithChild: '',
        dob: '',
        address: {
          streetAddress: [''],
          suburb: '',
          postcode: '',
          region: '',
          state: '',
          country: 'Australia',
        },
        homeContact: '',
        mobileContact: '',
        email: '',
        otherContact: '',
      },
    ],
  },
  {
    child: {
      doctor: {
        fullName: '',
        contactPhone: '',
        medicalService: {name: '', contact: ''},
        address: {
          streetAddress: [''],
          suburb: '',
          postcode: '',
          region: '',
          state: '',
          country: 'Australia',
        },
      },
      ambulanceSubscription: '',
      medicareCard: '',
      medicalCare: {
        healthCareNeed: {status: '', description: '', file: ''},
        allergy: {status: '', description: '', file: ''},
        anaphylaxis: {status: '', description: '', file: ''},
        NDISEnrolled: {status: '', description: '', file: ''},
      },
      allergyOrSensitivity: {status: '', file: ''},
      hasEpiPen: '',
      anaphylaxisManagement: '',
      riskManagement: '',
      diagnosedAsthma: {status: '', file: ''},
      medicationDevice: '',
      asthmaManagement: '',
      asthmaRiskManagement: '',
      anaphylaxisAwareness: '',
      policyOfAnaphylaxis: '',
      hasChildMedicalManagement: '',
    },
  },
  {
    childImmunised: '',
    childImmunisedFile: '',
    governmentAssistance: {
      claimCCS: '',
      CCSConfirmation: '',
    },
    immunisationUpToDate: '',
    childSchool: {
      goingToSchool: '',
      name: '',
      address: {
        streetAddress: [''],
        suburb: '',
        postcode: '',
        region: '',
        state: '',
        country: 'Australia',
      },
    },
    hasOtherService: '',
    otherServiceNotice: {
      startDate: '',
      lastDate: '',
    },
  },
  {
    permission: {
      medicalTreatment: '',
      medicalService: '',
      photographForMe2FDC: '',
      photographForReportage: '',
      photographForPosting: '',
      routineCheckUp: '',
      canTravelOnEducatorCar: '',
      canTravelOnPublicTransport: '',
      sunscreenAllow: '',
      allowSanitizer: '',
    },
  },
  {
    policyAgreement: false,
  },
  {
    childSchool: {
      goingToSchool: '',
      name: '',
      address: {
        streetAddress: [''],
        suburb: '',
        postcode: '',
        region: '',
        state: '',
        country: 'Australia',
      },
      termsDate: ['', '', '', ''],
      startsAt: '',
      endsAt: '',
    },
    childAssistance: {
      beforeSchool: '',
      afterSchool: '',
    },
  },
  {
    childSchedule: {
      schoolSchedule: [{day: '', beforeSchoolCareTime: '', afterSchoolCareTime: ''}],
      holidaySchedule: [{day: '', careTime: ''}],
    },
  },
  {
    child: {
      hasRelationWithEducator: '',
      relationWithEducator: '',
    },
  },
]

export const educatorFormObject: any = {
  '1': 'basicInformation',
  '2': 'emergencyContact',
  '3': 'qualifications',
  '4': 'inProgressQualifications',
  '5': 'trainings',
  '6': 'securityDetail',
  '7': 'statutoryDeclarationForm',
  '8': 'medicalCertificate',
}

export const days = [
  {
    value: 'Monday',
    label: 'Monday',
  },
  {
    value: 'Tuesday',
    label: 'Tuesday',
  },
  {
    value: 'Wednesday',
    label: 'Wednesday',
  },
  {
    value: 'Thursday',
    label: 'Thursday',
  },
  {
    value: 'Friday',
    label: 'Friday',
  },
  {
    value: 'Saturday',
    label: 'Saturday',
  },
]

export const certifications = [
  {
    label: 'Certificate III in Children’s Services',
    value: 'Certificate III in Children’s Services',
  },
  {label: 'Diploma in Children’s Services', value: 'Diploma in Children’s Services'},
  {
    label: 'Advanced Diploma in Children’s Services',
    value: 'Advanced Diploma in Children’s Services',
  },
  {label: 'Bachelor of Education', value: 'Bachelor of Education'},
  {
    label: 'Other relevant qualification, please give',
    value: 'Other relevant qualification, please give',
  },
]

export const mandatoryTraining = [
  {
    label: 'First Aid',
    value: 'First Aid',
  },
  {label: 'CPR', value: 'CPR'},
  {
    label: 'Advanced Diploma in Children’s Services',
    value: 'Advanced Diploma in Children’s Services',
  },
  {label: 'Anaphylaxis Management', value: 'Anaphylaxis Management'},
  {
    label: 'Asthma Management',
    value: 'Asthma Management',
  },
]

export const ChildEnrolmentSteps = [
  {
    title: 'Information about child',
    description: 'Your child information detail',
    stepValue: '1',
  },
  {
    title: 'Parent or guardian',
    description: 'Parent or guardian information',
    stepValue: '2',
  },
  {
    title: 'Authorised nominees',
    description: 'Child authorised nominees details',
    stepValue: '3',
  },
  {
    title: 'Child’s health information',
    description: 'About child’s health information',
    stepValue: '4',
  },
  {
    title: 'Immunisation record, payment of child care, other information',
    description: 'Immunisation and other details',
    stepValue: '5',
  },
  {
    title: 'Child authorisations',
    description: 'Child authorisations details',
    stepValue: '6',
  },
  {
    title: 'Agreement',
    description: 'Your agreement details',
    stepValue: '7',
  },
  {
    title: 'Details of school, kindergarten or childcare child attends',
    description: 'Details of school, kindergarten or childcare child attends',
    stepValue: '8',
  },
  {
    title: 'Schedule: child’s booked hours:',
    description: 'Child’s booked hours',
    stepValue: '9',
  },
  {
    title: 'Fee and charges',
    description: 'Fee and charges',
    stepValue: '10',
  },
  {
    title: 'Completed',
    description: 'Woah, we are here',
    stepValue: '11',
  },
]
export const ChildPostApprovalSteps = [
  {
    title: 'Parent and educator Agreement',
    description: 'Please fill out the form',
    stepValue: '1',
  },
  {
    title: 'Fee schedule',
    description: 'Please fill out the form',
    stepValue: '2',
  },
  {
    title: 'Complying Written Arrangement Form',
    description: 'Complying Written Arrangement Form',
    stepValue: '3',
  },
  {
    title: 'Statutory Declaration form',
    description: 'Statutory Declaration form',
    stepValue: '4',
  },
  {
    title: 'Saturday Statutory Declaration form',
    description: 'Saturday Statutory Declaration form',
    stepValue: '5',
  },
  {
    title: 'Regular Transportation Permission Form',
    description: 'Regular Transportation Permission Form',
    stepValue: '6',
  },
  {
    title: 'Application Submitted',
    description: 'Almost done',
    stepValue: '7',
  },
]

export const languagesSpoken = [
  {value: 'Chinese', label: 'Chinese'},
  {value: 'Spanish', label: 'Spanish'},
  {value: 'English', label: 'English'},
  {value: 'Hindi', label: 'Hindi'},
  {value: 'Urdu', label: 'Urdu'},
  {value: 'Arabic', label: 'Arabic'},
  {value: 'Portuguese', label: 'Portuguese'},
  {value: 'Bengali', label: 'Bengali'},
  {value: 'Russian', label: 'Russian'},
  {value: 'Japanese', label: 'Japanese'},
  {value: 'Punjabi', label: 'Punjabi'},
  {value: 'German', label: 'German'},
  {value: 'Javanese', label: 'Javanese'},
  {value: 'Wu Chinese', label: 'Wu Chinese'},
  {value: 'Korean', label: 'Korean'},
  {value: 'French', label: 'French'},
  {value: 'Vietnamese', label: 'Vietnamese'},
  {value: 'Telugu', label: 'Telugu'},
  {value: 'Marathi', label: 'Marathi'},
  {value: 'Tamil', label: 'Tamil'},
  {value: 'Turkish', label: 'Turkish'},
]

export const countriesList = [
  {label: 'Afghanistan', value: 'Afghanistan'},
  {label: 'Åland Islands', value: 'Åland Islands'},
  {label: 'Albania', value: 'Albania'},
  {label: 'Algeria', value: 'Algeria'},
  {label: 'American Samoa', value: 'American Samoa'},
  {label: 'AndorrA', value: 'AndorrA'},
  {label: 'Angola', value: 'Angola'},
  {label: 'Anguilla', value: 'Anguilla'},
  {label: 'Antarctica', value: 'Antarctica'},
  {label: 'Antigua and Barbuda', value: 'Antigua and Barbuda'},
  {label: 'Argentina', value: 'Argentina'},
  {label: 'Armenia', value: 'Armenia'},
  {label: 'Aruba', value: 'Aruba'},
  {label: 'Australia', value: 'Australia'},
  {label: 'Austria', value: 'Austria'},
  {label: 'Azerbaijan', value: 'Azerbaijan'},
  {label: 'Bahamas', value: 'Bahamas'},
  {label: 'Bahrain', value: 'Bahrain'},
  {label: 'Bangladesh', value: 'Bangladesh'},
  {label: 'Barbados', value: 'Barbados'},
  {label: 'Belarus', value: 'Belarus'},
  {label: 'Belgium', value: 'Belgium'},
  {label: 'Belize', value: 'Belize'},
  {label: 'Benin', value: 'Benin'},
  {label: 'Bermuda', value: 'Bermuda'},
  {label: 'Bhutan', value: 'Bhutan'},
  {label: 'Bolivia', value: 'Bolivia'},
  {label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina'},
  {label: 'Botswana', value: 'Botswana'},
  {label: 'Bouvet Island', value: 'Bouvet Island'},
  {label: 'Brazil', value: 'Brazil'},
  {label: 'British Indian Ocean Territory', value: 'British Indian Ocean Territory'},
  {label: 'Brunei Darussalam', value: 'Brunei Darussalam'},
  {label: 'Bulgaria', value: 'Bulgaria'},
  {label: 'Burkina Faso', value: 'Burkina Faso'},
  {label: 'Burundi', value: 'Burundi'},
  {label: 'Cambodia', value: 'Cambodia'},
  {label: 'Cameroon', value: 'Cameroon'},
  {label: 'Canada', value: 'Canada'},
  {label: 'Cape Verde', value: 'Cape Verde'},
  {label: 'Cayman Islands', value: 'Cayman Islands'},
  {label: 'Central African Republic', value: 'Central African Republic'},
  {label: 'Chad', value: 'Chad'},
  {label: 'Chile', value: 'Chile'},
  {label: 'China', value: 'China'},
  {label: 'Christmas Island', value: 'Christmas Island'},
  {label: 'Cocos (Keeling) Islands', value: 'Cocos (Keeling) Islands'},
  {label: 'Colombia', value: 'Colombia'},
  {label: 'Comoros', value: 'Comoros'},
  {label: 'Congo', value: 'Congo'},
  {label: 'Congo, The Democratic Republic of the', value: 'Congo, The Democratic Republic of the'},
  {label: 'Cook Islands', value: 'Cook Islands'},
  {label: 'Costa Rica', value: 'Costa Rica'},
  {label: "Cote D'Ivoire", value: "Cote D'Ivoire"},
  {label: 'Croatia', value: 'Croatia'},
  {label: 'Cuba', value: 'Cuba'},
  {label: 'Cyprus', value: 'Cyprus'},
  {label: 'Czech Republic', value: 'Czech Republic'},
  {label: 'Denmark', value: 'Denmark'},
  {label: 'Djibouti', value: 'Djibouti'},
  {label: 'Dominica', value: 'Dominica'},
  {label: 'Dominican Republic', value: 'Dominican Republic'},
  {label: 'Ecuador', value: 'Ecuador'},
  {label: 'Egypt', value: 'Egypt'},
  {label: 'El Salvador', value: 'El Salvador'},
  {label: 'Equatorial Guinea', value: 'Equatorial Guinea'},
  {label: 'Eritrea', value: 'Eritrea'},
  {label: 'Estonia', value: 'Estonia'},
  {label: 'Ethiopia', value: 'Ethiopia'},
  {label: 'Falkland Islands (Malvinas', value: 'Falkland Islands (Malvinas)'},
  {label: 'Faroe Islands', value: 'Faroe Islands'},
  {label: 'Fiji', value: 'Fiji'},
  {label: 'Finland', value: 'Finland'},
  {label: 'France', value: 'France'},
  {label: 'French Guiana', value: 'French Guiana'},
  {label: 'French Polynesia', value: 'French Polynesia'},
  {label: 'French Southern Territories', value: 'French Southern Territories'},
  {label: 'Gabon', value: 'Gabon'},
  {label: 'Gambia', value: 'Gambia'},
  {label: 'Georgia', value: 'Georgia'},
  {label: 'Germany', value: 'Germany'},
  {label: 'Ghana', value: 'Ghana'},
  {label: 'Gibraltar', value: 'Gibraltar'},
  {label: 'Greece', value: 'Greece'},
  {label: 'Greenland', value: 'Greenland'},
  {label: 'Grenada', value: 'Grenada'},
  {label: 'Guadeloupe', value: 'Guadeloupe'},
  {label: 'Guam', value: 'Guam'},
  {label: 'Guatemala', value: 'Guatemala'},
  {label: 'Guernsey', value: 'Guernsey'},
  {label: 'Guinea', value: 'Guinea'},
  {label: 'Guinea-Bissau', value: 'Guinea-Bissau'},
  {label: 'Guyana', value: 'Guyana'},
  {label: 'Haiti', value: 'Haiti'},
  {label: 'Heard Island and Mcdonald Islands', value: 'Heard Island and Mcdonald Islands'},
  {label: 'Holy See (Vatican City State)', value: 'Holy See (Vatican City State)'},
  {label: 'Honduras', value: 'Honduras'},
  {label: 'Hong Kong', value: 'Hong Kong'},
  {label: 'Hungary', value: 'Hungary'},
  {label: 'Iceland', value: 'Iceland'},
  {label: 'India', value: 'India'},
  {label: 'Indonesia', value: 'Indonesia'},
  {label: 'Iran Islamic Republic Of', value: 'Iran, Islamic Republic Of'},
  {label: 'Iraq', value: 'Iraq'},
  {label: 'Ireland', value: 'Ireland'},
  {label: 'Isle  of Man', value: 'Isle of Man'},
  {label: 'Italy', value: 'Italy'},
  {label: 'Jamaica', value: 'Jamaica'},
  {label: 'Japan', value: 'Japan'},
  {label: 'Jersey', value: 'Jersey'},
  {label: 'Jordan', value: 'Jordan'},
  {label: 'Kazakhstan', value: 'Kazakhstan'},
  {label: 'Kenya', value: 'Kenya'},
  {label: 'Kiribati', value: 'Kiribati'},
  {
    label: "Korea, Democratic People'S Republic of",
    value: "Korea, Democratic People'S Republic of",
  },
  {label: 'Korea, Republic of', value: 'Korea, Republic of'},
  {label: 'Kuwait', value: 'Kuwait'},
  {label: 'Kyrgyzstan', value: 'Kyrgyzstan'},
  {label: "Lao People'S Democratic Republic", value: "Lao People'S Democratic Republic"},
  {label: 'Latvia', value: 'Latvia'},
  {label: 'Lebanon', value: 'Lebanon'},
  {label: 'Lesotho', value: 'Lesotho'},
  {label: 'Liberia', value: 'Liberia'},
  {label: 'Libyan Arab Jamahiriya', value: 'Libyan Arab Jamahiriya'},
  {label: 'Liechtenstein', value: 'Liechtenstein'},
  {label: 'Lithuania', value: 'Lithuania'},
  {label: 'Luxembourg', value: 'Luxembourg'},
  {label: 'Macao', value: 'Macao'},
  {
    label: 'Macedonia, The Former Yugoslav Republic of',
    value: 'Macedonia, The Former Yugoslav Republic of',
  },
  {label: 'Madagascar', value: 'Madagascar'},
  {label: 'Malawi', value: 'Malawi'},
  {label: 'Malaysia', value: 'Malaysia'},
  {label: 'Maldives', value: 'Maldives'},
  {label: 'Mali', value: 'Mali'},
  {label: 'Malta', value: 'Malta'},
  {label: 'Marshall Islands', value: 'Marshall Islands'},
  {label: 'Martinique', value: 'Martinique'},
  {label: 'Mauritania', value: 'Mauritania'},
  {label: 'Mauritius', value: 'Mauritius'},
  {label: 'Mayotte', value: 'Mayotte'},
  {label: 'Mexico', value: 'Mexico'},
  {label: 'Micronesia, Federated States of', value: 'Micronesia, Federated States of'},
  {label: 'Moldova, Republic of', value: 'Moldova, Republic of'},
  {label: 'Monaco', value: 'Monaco'},
  {label: 'Mongolia', value: 'Mongolia'},
  {label: 'Montserrat', value: 'Montserrat'},
  {label: 'Morocco', value: 'Morocco'},
  {label: 'Mozambique', value: 'Mozambique'},
  {label: 'Myanmar', value: 'Myanmar'},
  {label: 'Namibia', value: 'Namibia'},
  {label: 'Nauru', value: 'Nauru'},
  {label: 'Nepal', value: 'Nepal'},
  {label: 'Netherlands', value: 'Netherlands'},
  {label: 'Netherlands Antilles', value: 'Netherlands Antilles'},
  {label: 'New Caledonia', value: 'New Caledonia'},
  {label: 'New Zealand', value: 'New Zealand'},
  {label: 'Nicaragua', value: 'Nicaragua'},
  {label: 'Niger', value: 'Niger'},
  {label: 'Nigeria', value: 'Nigeria'},
  {label: 'Niue', value: 'Niue'},
  {label: 'Norfolk Island', value: 'Norfolk Island'},
  {label: 'Northern Mariana Islands', value: 'Northern Mariana Islands'},
  {label: 'Norway', value: 'Norway'},
  {label: 'Oman', value: 'Oman'},
  {label: 'Pakistan', value: 'Pakistan'},
  {label: 'Palau', value: 'Palau'},
  {label: 'Palestinian Territory, Occupied', value: 'Palestinian Territory, Occupied'},
  {label: 'Panama', value: 'Panama'},
  {label: 'Papua New Guinea', value: 'Papua New Guinea'},
  {label: 'Paraguay', value: 'Paraguay'},
  {label: 'Peru', value: 'Peru'},
  {label: 'Philippines', value: 'Philippines'},
  {label: 'Pitcairn', value: 'Pitcairn'},
  {label: 'Poland', value: 'Poland'},
  {label: 'Portugal', value: 'Portugal'},
  {label: 'Puerto Rico', value: 'Puerto Rico'},
  {label: 'Qatar', value: 'Qatar'},
  {label: 'Reunion', value: 'Reunion'},
  {label: 'Romania', value: 'Romania'},
  {label: 'Russian Federation', value: 'Russian Federation'},
  {label: 'RWANDA', value: 'RWANDA'},
  {label: 'Saint Helena', value: 'Saint Helena'},
  {label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis'},
  {label: 'Saint Lucia', value: 'Saint Lucia'},
  {label: 'Saint Pierre and Miquelon', value: 'Saint Pierre and Miquelon'},
  {label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines'},
  {label: 'Samoa', value: 'Samoa'},
  {label: 'San Marino', value: 'San Marino'},
  {label: 'Sao Tome and Principe', value: 'Sao Tome and Principe'},
  {label: 'Saudi Arabia', value: 'Saudi Arabia'},
  {label: 'Senegal', value: 'Senegal'},
  {label: 'Serbia and Montenegro', value: 'Serbia and Montenegro'},
  {label: 'Seychelles', value: 'Seychelles'},
  {label: 'Sierra Leone', value: 'Sierra Leone'},
  {label: 'Singapore', value: 'Singapore'},
  {label: 'Slovakia', value: 'Slovakia'},
  {label: 'Slovenia', value: 'Slovenia'},
  {label: 'Solomon Islands', value: 'Solomon Islands'},
  {label: 'Somalia', value: 'Somalia'},
  {label: 'South Africa', value: 'South Africa'},
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'South Georgia and the South Sandwich Islands',
  },
  {label: 'Spain', value: 'Spain'},
  {label: 'Sri Lanka', value: 'Sri Lanka'},
  {label: 'Sudan', value: 'Sudan'},
  {label: 'Suriname', value: 'Suriname'},
  {label: 'Svalbard and Jan Mayen', value: 'Svalbard and Jan Mayen'},
  {label: 'Swaziland', value: 'Swaziland'},
  {label: 'Sweden', value: 'Sweden'},
  {label: 'Switzerland', value: 'Switzerland'},
  {label: 'Syrian Arab Republic', value: 'Syrian Arab Republic'},
  {label: 'Taiwan, Province of China', value: 'Taiwan, Province of China'},
  {label: 'Tajikistan', value: 'Tajikistan'},
  {label: 'Tanzania, United Republic of', value: 'Tanzania, United Republic of'},
  {label: 'Thailand', value: 'Thailand'},
  {label: 'Timor-Leste', value: 'Timor-Leste'},
  {label: 'Togo', value: 'Togo'},
  {label: 'Tokelau', value: 'Tokelau'},
  {label: 'Tonga', value: 'Tonga'},
  {label: 'Trinidad and Tobago', value: 'Trinidad and Tobago'},
  {label: 'Tunisia', value: 'Tunisia'},
  {label: 'Turkey', value: 'Turkey'},
  {label: 'Turkmenistan', value: 'Turkmenistan'},
  {label: 'Turks and Caicos Islands', value: 'Turks and Caicos Islands'},
  {label: 'Tuvalu', value: 'Tuvalu'},
  {label: 'Uganda', value: 'Uganda'},
  {label: 'Ukraine', value: 'Ukraine'},
  {label: 'United Arab Emirates', value: 'United Arab Emirates'},
  {label: 'United Kingdom', value: 'United Kingdom'},
  {label: 'United States', value: 'United States'},
  {label: 'United States Minor Outlying Islands', value: 'United States Minor Outlying Islands'},
  {label: 'Uruguay', value: 'Uruguay'},
  {label: 'Uzbekistan', value: 'Uzbekistan'},
  {label: 'Vanuatu', value: 'Vanuatu'},
  {label: 'Venezuela', value: 'Venezuela'},
  {label: 'Viet Nam', value: 'Viet Nam'},
  {label: 'Virgin Islands, British', value: 'Virgin Islands, British'},
  {label: 'Virgin Islands, U.S.', value: 'Virgin Islands, U.S.'},
  {label: 'Wallis and Futuna', value: 'Wallis and Futuna'},
  {label: 'Western Sahara', value: 'Western Sahara'},
  {label: 'Yemen', value: 'Yemen'},
  {label: 'Zambia', value: 'Zambia'},
  {label: 'Zimbabwe', value: 'Zimbabwe'},
]

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const mergeDeep: any = (target: any, ...sources: any) => {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, {[key]: {}})
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, {[key]: source[key]})
      }
    }
  }

  return mergeDeep(target, ...sources)
}
