import React from 'react'
import {ErrorMessage, Field} from 'formik'
import {regions} from '../educator/educator-form/core/EducatorHelpers'

export const AddressDetailInfo = ({
  values,
  setFieldValue,
  field,
  title = '',
  mandatory = true,
}: any) => {
  return (
    <>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className={`${mandatory ? 'required' : ''}`}>
            {title ? title : 'Address line 1'}
          </span>
        </label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={`${field}.streetAddress[0]`}
          placeholder='Address line 1'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`${field}.streetAddress[0]`} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={`${field}.streetAddress[1]`}
          placeholder='Address line 2'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`${field}.streetAddress[1]`} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>Suburb</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`${field}.suburb`}
              placeholder='Suburb'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.suburb`} />
            </div>
          </div>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>Region</span>
            </label>
            <Field as='select' name={`${field}.region`} className='form-select form-select-solid'>
              <option value=''>Select Region</option>
              {regions.map((region, index) => (
                <option value={region} key={index}>
                  {region}
                </option>
              ))}
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.region`} />
            </div>
          </div>
        </div>
      </div>
      <div className='fv-row mb-10'>
        <div className='row'>
          <div className='col-md-4 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>Postcode</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`${field}.postcode`}
              placeholder='Postcode'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.postcode`} />
            </div>
          </div>
          <div className='col-md-4 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>State</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`${field}.state`}
              placeholder='State'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.state`} />
            </div>
          </div>
          <div className='col-md-4 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>Country</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`${field}.country`}
              placeholder='Country'
              disabled
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.country`} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
