import React from 'react'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {EducatorEditForm} from './child-form/components/ChildEditForm'
import {ChildPostApprovalForm} from './child-post-approval/components/ChildPostApprovalForm'
// import {UsersListWrapper} from './users-list/UsersList'

const ChildEnrolmentPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='add' element={<EducatorEditForm />} />
        <Route path='edit/:id' element={<EducatorEditForm />} />
        <Route path='post-approval/:id' element={<ChildPostApprovalForm />} />
      </Route>
      <Route index element={<Navigate to='/child/add' />} />
    </Routes>
  )
}

export default ChildEnrolmentPage
