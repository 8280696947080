/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {Field, ErrorMessage} from 'formik'
import {useAuth} from '../../../../../auth'
import Collapsible from '../../../../common/Collapsible'
import ChildHeader from '../../../../common/ChildFormHeader'
import {ParentsDetailInfo} from '../../../../common/ParentDetailForm'
import UploadFile from '../../../../common/UploadFile'

const ParentGuardianForm: FC<any> = ({values, setFieldValue}) => {
  const {currentUser} = useAuth()

  useEffect(() => {
    if (
      !values?.guardian?.responsible &&
      values?.signature &&
      values?.guardian?.responsible !== currentUser?.relationWithChild
    ) {
      setFieldValue('guardian.responsible', currentUser?.relationWithChild)
      setFieldValue('guardian.centrelinkRecord', currentUser?.centrelinkRecord)
      setFieldValue(`${currentUser?.relationWithChild}.email`, currentUser?.email)
      setFieldValue(`${currentUser?.relationWithChild}.fullName`, currentUser?.fullName)
    }
  }, [values?.signature])

  return (
    <div className='w-100'>
      <ChildHeader />
      <div className='pb-10 pb-lg-15'>
        <div className='shadow-sm'>
          <div className='d-flex flex-column p-9'>
            <div className='fv-row'>
              <div className='row'>
                <div className='col-md-6 fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Responsible for guardianship</span>
                  </label>

                  <Field
                    as='select'
                    name='guardian.responsible'
                    className='form-select form-select-solid'
                    disabled
                  >
                    <option value=''>Select relation</option>
                    <option value='father'>Father</option>
                    <option value='mother'>Mother</option>
                    <option value='both'>Both</option>
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='guardian.responsible' />
                  </div>
                </div>

                <div className='col-md-6 fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span>Child record under centrelink</span>
                  </label>
                  <Field
                    as='select'
                    name='guardian.centrelinkRecord'
                    className='form-select form-select-solid'
                    disabled
                  >
                    <option>Select Relation</option>
                    <option value='father'>Father</option>
                    <option value='mother'>Mother</option>
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='guardian.centrelinkRecord' />
                  </div>
                </div>
              </div>
            </div>
            {(values?.guardian?.responsible === 'mother' ||
              values?.guardian?.responsible === 'both') && (
              <Collapsible title={"Mother's 'information'"} id='mother-info'>
                <ParentsDetailInfo
                  values={values?.mother}
                  setFieldValue={setFieldValue}
                  field='mother'
                />
              </Collapsible>
            )}

            {(values?.guardian?.responsible === 'father' ||
              values?.guardian?.responsible === 'both') && (
              <Collapsible title="Father's information" id='father-info'>
                <ParentsDetailInfo
                  values={values?.father}
                  setFieldValue={setFieldValue}
                  field='father'
                />
              </Collapsible>
            )}
            <Collapsible title='Second parent information' id='second-parent-info'>
              <ParentsDetailInfo
                values={values?.secondParent}
                setFieldValue={setFieldValue}
                field='secondParent'
                mandatory={false}
              />
            </Collapsible>

            <Collapsible title='Court orders, parenting plan, parenting orders' id='court-orders'>
              <div className='row'>
                <span className='align-items-center fs-5  m-2 col-4 '>
                  <Field type='radio' className='me-2' name='courtOrders.anyOrder' value='yes' />
                  Yes
                </span>
                <span className='align-items-center fs-5  m-2 col-4 '>
                  <Field type='radio' className='me-2' name='courtOrders.anyOrder' value='no' />
                  No
                </span>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='courtOrders.anyOrder' />
                </div>
              </div>
              {values?.courtOrders?.anyOrder === 'yes' && (
                <div className='row'>
                  <p>
                    Please provide a copy if there are any we only seek copies of orders, parenting
                    plans, or parenting orders that relate to the powers, duties, responsibilities
                    or authorities of any person in relation to the child or access to the child.
                    this will include orders about who the child lives with and how the child spends
                    time with a parent or other person.
                  </p>
                  <UploadFile
                    field='courtOrders.courtOrderFile'
                    setFieldValue={setFieldValue}
                    value={values?.courtOrders?.courtOrderFile}
                  />
                </div>
              )}
            </Collapsible>
            <Collapsible title='Any special considerations for the child' id='special-info'>
              <div className='row fv-row  mb-10'>
                <label className='d-flex fs-5 fw-bold mb-2'>
                  <span className='required'>Does the child live with the mother?</span>
                </label>

                <Field
                  className='form-control form-control-solid mb-2'
                  as='textarea'
                  rows={4}
                  placeholder='Does the child live with the mother?'
                  name='specialConsiderations.childLiveWithMother'
                ></Field>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='specialConsiderations.childLiveWithMother' />
                </div>
              </div>
              <div className='row fv-row  mb-10'>
                <label className='d-flex fs-5 fw-bold mb-2'>
                  <span className='required'>Please provide details of religious requirements</span>
                </label>
                <Field
                  className='form-control form-control-solid mb-2'
                  as='textarea'
                  rows={4}
                  placeholder='Please provide details of religious requirements'
                  name='specialConsiderations.religiousInfo'
                ></Field>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='specialConsiderations.religiousInfo' />
                </div>
              </div>
              <div className='row fv-row  mb-10'>
                <label className='d-flex fs-5 fw-bold mb-2'>
                  <span className='required'>Please provide details of dietary requirements</span>
                </label>

                <Field
                  className='form-control form-control-solid mb-2'
                  as='textarea'
                  rows={4}
                  placeholder='Please provide details of dietary requirements'
                  name='specialConsiderations.dietaryInfo'
                ></Field>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='specialConsiderations.dietaryInfo' />
                </div>
              </div>
              <div className='row fv-row  mb-10'>
                <label className='d-flex fs-5 fw-bold mb-2'>
                  <span className='required'>
                    Please provide any other special requirements for your child
                  </span>
                </label>

                <Field
                  className='form-control form-control-solid mb-2'
                  as='textarea'
                  rows={4}
                  placeholder='Please provide any other special requirements for your child'
                  name='specialConsiderations.otherRequirements'
                ></Field>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='specialConsiderations.otherRequirements' />
                </div>
              </div>
            </Collapsible>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ParentGuardianForm}
