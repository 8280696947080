import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const ThankYouPage: FC = () => {
  return (
    <div className='vh-100 w-100 d-flex justify-content-center align-items-center'>
      <div>
        <div className='mb-4 text-center'>
          <img
            src={toAbsoluteUrl('/media/icons8-confetti.svg')}
            className='w-100px theme-light-show'
            alt=''
          />
        </div>
        <div className='text-center'>
          <h1>Thank You !</h1>
          <p>Your application has been completed successfully.</p>
        </div>
      </div>
    </div>
  )
}

export {ThankYouPage}
