import {FC} from 'react'
import {ErrorMessage, Field} from 'formik'
import {AddressDetailInfo} from '../../../../common/AddressDetailInfo'
import {SignatureField} from '../../../../common/SignatureField'

interface Props {
  values: any
  setFieldValue: any
}

const ParentEducatorAgreementPostForm: FC<Props> = ({values, setFieldValue}) => {
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>Parent Educator Agreement</h2>
      </div>

      <fieldset disabled>
        <div className='row'>
          <div className='col-md-12 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span>Name of schemes Representative:</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='representativeName'
              placeholder='Name of schemes Representative'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='representativeName' />
            </div>
          </div>
          <span>
            The scheme must ensure that the parent/guardian and educator fully comply with this
            agreement Part One: Parent/Guardian Section (this part should be completed by the
            Parent/Guardian)
          </span>
          <div className='col-md-12 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span>Parent/Guardian Name:</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`${[values?.guardian?.responsible]}.fullName`}
              placeholder='Name'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='parentName' />
            </div>
          </div>
        </div>

        <AddressDetailInfo
          values={values?.[values?.guardian?.responsible]?.address}
          setFieldValue={setFieldValue}
          field={`${[values?.guardian?.responsible]}.address`}
          mandatory={false}
        />

        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span>Phone Home:</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`${[values?.guardian?.responsible]}.homeContact`}
              placeholder='Phone Number'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='phoneHome' />
            </div>
          </div>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span>Mobile Number:</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`${[values?.guardian?.responsible]}.mobileContact`}
              placeholder='Mobile Number'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='mobileNumber' />
            </div>
          </div>
        </div>
      </fieldset>

      <div className='row'>
        <div className='col-md-12 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Child’s/Children’s Name/s:</span>
          </label>

          <Field
            className='form-control form-control-solid mb-2'
            as='textarea'
            rows={4}
            placeholder='Child’s/Children’s Name/s'
            name='parentEducatorAgreement.childrenNames'
          ></Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='parentEducatorAgreement.childrenNames' />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 fv-row mb-5'>
          <fieldset disabled>
            <span>
              1. I
              <Field
                type='text'
                className='w-auto form-control form-control-lg form-control-solid d-inline mx-2'
                name={`${[values?.guardian?.responsible]}.fullName`}
                placeholder='Parent Name'
              />
              have inspected the Family Day Care Service provided by
              <Field
                type='text'
                className='w-auto form-control form-control-lg form-control-solid d-inline mx-2 mb-5'
                name='educator.basicInformation.fullName'
                placeholder='Name of Educator'
              />
              at <strong>(Address of Educator Residence)</strong>
              <AddressDetailInfo
                values={values}
                setFieldValue={setFieldValue}
                field='educator.basicInformation.dayCareAddress'
                mandatory={false}
              />
              <span className='mt-n5 d-block my-5'>
                And agree to place the above-named child/children in the care of the Educator as set
                out in the Enrolment Form, or as varied from time to time in accordance with
                Attendance Records signed by the Parent/Guardian.
              </span>
            </span>
          </fieldset>

          <span className='d-block my-5'>
            2. I agree to comply with all Government requirements in regard to the service provided
            by the educator as set out in the Policies and Procedures of the Family Day Care.
          </span>
          <span className='d-block my-5'>
            3. I agree to pay the Educator all appropriate fees (and penalty fees if applicable).
          </span>

          <span className='d-block my-5'>
            4. I am aware of the following domestic pets kept at the home:
            <Field
              className='form-control form-control-solid mb-2 my-5'
              as='textarea'
              rows={4}
              placeholder='Domestic pets ..'
              name='parentEducatorAgreement.domesticPets'
            ></Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='parentEducatorAgreement.domesticPets' />
            </div>
            <span className='d-block my-5'>
              * and agree to my child being allowed limited access to such pets in accordance with
              the Education and Care Services National Law Act 2010 and the Education and Care
              Services National Regulations and Scheme policies and Procedures
            </span>
            <span className='d-block my-5'>
              * request that my child be denied access to such pets at all times.
            </span>
          </span>
          <span className='d-block my-5'>
            5. I understand that in the event of accident/injury occurring to my child my Educator
            will:
            <span className='d-block my-5'>- administer appropriate first aid to my child</span>
            <span className='d-block my-5'>
              - make every effort to contact parent or the notified emergency contact persons
            </span>
            <span className='d-block my-5'>
              - seek medical attention for my child if required from his/her doctor/dentist or the
              nearest hospital
            </span>
            <span className='d-block my-5'>- arrange for transportation by ambulance</span>
            <span className='d-block my-5'>
              I agree to meet any expenses incurred and request that the following action also be
              taken:
            </span>
            <Field
              className='form-control form-control-solid mb-2'
              as='textarea'
              rows={4}
              placeholder='Expenses Incurred'
              name='parentEducatorAgreement.expensesIncurred'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='parentEducatorAgreement.expensesIncurred' />
            </div>
            <span className='d-block my-5'>
              6. I understand that the safety, health and wellbeing of child will be the highest
              priority and provided for my child whilst in care and that my Educator holds public
              liability insurance. I further understand that this insurance is not the
              responsibility of the Scheme or the Director of the Family day care should an accident
              or injury occurs.
            </span>
            <span className='d-block my-5'>
              7. I understand that my Educator may terminate this Agreement following consultation
              and liaison with the Scheme Co-coordinator and in the case of permanently booked care,
              upon giving parent/guardian a minimum fourteen (14) days’ notice in writing and
              refunding any payments in credit.
            </span>
          </span>
        </div>
      </div>
      <h1 className='fw-bolder text-dark pb-5'>Educator Section</h1>
      <fieldset disabled>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span>Educator’s Name:</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Educator Name'
              name='educator.basicInformation.fullName'
            />
          </div>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span>Phone No:</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid '
              name='educator.basicInformation.contactPhone[0]'
              placeholder='Educator Phone'
            />
          </div>
          <AddressDetailInfo
            values={values}
            setFieldValue={setFieldValue}
            field='educator.basicInformation.dayCareAddress'
            mandatory={false}
          />
          <div className='row'>
            <div className='col-md-6 fv-row mb-5'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span>Parent Name:</span>
              </label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid '
                name={`${[values?.guardian?.responsible]}.fullName`}
                placeholder='Parent Name'
                disabled
              />
            </div>

            <div className='col-md-6 fv-row mb-5'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span>Child/Children’s Names:</span>
              </label>

              <Field
                className='form-control form-control-solid mb-2'
                as='textarea'
                rows={4}
                placeholder='Child’s/Children’s Name/s'
                name='parentEducatorAgreement.childrenNames'
                disabled
              ></Field>
            </div>
          </div>
          <div className='row'>
            <span>
              I, have inspected the Family Day Care Service provided by
              <Field
                type='text'
                className='w-auto form-control form-control-lg form-control-solid d-inline mx-2 mb-5'
                name='educator.basicInformation.fullName'
                placeholder='Name of Educator'
              />
              agree to provide education and care service for the above child/children of
              <Field
                type='text'
                className='w-auto form-control form-control-lg form-control-solid d-inline mx-2 mb-5'
                name={`${[values?.guardian?.responsible]}.fullName`}
                placeholder='Name of Parent'
                disabled
              />
              <span className=''>
                on the days and for the hours set out in the Child Enrolment Form or as varied from
                time to time in accordance with Attendance Records signed by the parent.
              </span>
              <span className='d-block my-5'>
                I agree to abide by the policies and procedures of the Family Day Care, the
                Education and Care Services National Law Act 2010 and the Education and Care
                Services National Regulations, Policies of Commonwealth Department of Employment,
                Education & Workplace Relations (DEEWR) and The Family Assistance Office (FAO) in
                relation to the care provided at all times.
              </span>
              <span className='d-block'>
                I agree to accept only the fees properly payable for the education and care provided
                or as set out in the fee schedule provided by myself.
              </span>
              <span className='d-block my-5'>
                Agree to consult and liaise with the Co-coordinator of the Scheme and the parent
                prior to terminating this Agreement or reducing my availability to provide education
                and care and to provide a minimum fourteen (14) days’ notice in writing of my
                intention to terminate or reduce availability.
              </span>
              <span className='d-block'>
                I agree to provide fourteen (14) days prior notice to the parent of my intention to
                take a leave of absence from the provision of care (or in the case of emergency
                leave with as much notice as possible) and to assist with arrangement for
                alternative care of the child/children during my absence.
              </span>
              <span className='d-block my-5'>
                I agree to use my best endeavors to deny access to the child/children to any person
                other than those nominated on the Enrolment Form unless the child is under my
                constant supervision or prior consent of the parent has been obtained.
              </span>
              <span className='d-block my-5'>
                I agree to use my best endeavors to deny access to the child/children to any person
                other than those nominated on the Enrolment Form unless the child is under my
                constant supervision or prior consent of the parent has been obtained.
              </span>
              <span className='d-block'>
                I agree to restrict outings and excursions available to the child to those agreed to
                in writing by the parent prior to such outings or excursions taking place.
              </span>
              <span className='d-block my-5'>
                I agree to keep the parent informed about the child/children’s education progress
                and daily activities whilst in care and to be available to discuss issues relating
                to care at a mutually agreed time.
              </span>
              <span className='d-block my-5'>
                I agree to respect the privacy of the parent and the child/children by ensuring that
                any information regarding the family identity and circumstances and/or the behavior
                and progress of the child/children is kept strictly confidential at all times. I
                further agree to liaise only with the Parent, Scheme staff and person authorized by
                the National Regulations in regard to such information.
              </span>
              <span className='d-block'>
                I agree to administer medication as requested by the parent in accordance with the
                parent’s written authority, the National Regulations and Scheme Policies only.
              </span>
            </span>
          </div>
          <div className='row pt-8'>
            <div className='col-6'>
              <label className='align-items-center fs-5 pe-1 fw-bold mb-2'>
                <span className='fw-bolder text-dark'>Parent Name</span>
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name={`${[values?.guardian?.responsible]}.fullName`}
                placeholder='Parent Name'
              />
            </div>

            <div className='col-6'>
              <SignatureField
                title='Parent Signature'
                field='signature'
                value={values?.signature}
                setFieldValue={setFieldValue}
                disabled={true}
              />
            </div>
          </div>

          <div className='row pt-8'>
            <div className='col-6'>
              <label className='align-items-center fs-5 pe-1 fw-bold mb-2'>
                <span className='fw-bolder text-dark'>Scheme Representative Name</span>
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='schedule.representativeName'
                placeholder='Representative Name'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='schedule.representativeName' />
              </div>
            </div>

            <div className='col-6'>
              <SignatureField
                title='Scheme Representative Signature'
                field='representativeSignature'
                value={values?.representativeSignature}
                setFieldValue={setFieldValue}
                disabled={true}
              />
            </div>
          </div>

          <div className='row pt-8'>
            <div className='col-6'>
              <label className='align-items-center fs-5 pe-1 fw-bold mb-2'>
                <span className='fw-bolder text-dark'>Educator Name</span>
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='educator.basicInformation.fullName'
                placeholder='Educator Name'
              />
            </div>

            <div className='col-6'>
              <SignatureField
                title='Educator Signature'
                field='educator.signature'
                value={values?.educator?.signature}
                setFieldValue={setFieldValue}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  )
}

export {ParentEducatorAgreementPostForm}
