import React, {FC} from 'react'

interface Step {
  title: string
  description: string
  subStep?: boolean
  stepValue: string
  subStepArr?: string[]
}

interface Props {
  steps: Step[]
  stepNumber?: number
}
const stepArr = ['4.1', '4.2', '4.3', '4.4', '4.5', '4.6', '4.7']

export const StepperAsideMenu: FC<Props> = ({steps, stepNumber}) => {
  return (
    <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
      {/* begin::Wrapper*/}
      <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
        {/* begin::Nav*/}
        <div className='stepper-nav'>
          {steps.map((step: Step, index: number) => {
            return (
              <div
                className={`stepper-item   ${index === 0 && 'current'} ${
                  step?.subStep ? 'sub-step' : ''
                }`}
                data-kt-stepper-element='nav'
                key={index}
              >
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper align-items-sm-start'>
                  {/* begin::Icon*/}

                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>{step.stepValue}</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{step?.title}</h3>

                    <div className={`stepper-desc fw-semibold`}>{step?.description}</div>
                  </div>
                </div>
                {/* end::Label*/}
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                {steps?.length !== index + 1 && <div className='stepper-line h-40px'></div>}

                {/* end::Line*/}
              </div>
            )
          })}
        </div>
        {/* end::Nav*/}
      </div>
      {/* end::Wrapper*/}
    </div>
  )
}
