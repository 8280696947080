/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, RefObject, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {StepperComponent} from '../../../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ParentEducatorAgreementForm} from './steps/ParentEducatorAgreementForm'
import {EducatorCompletionSuccess} from './steps/EducatorCompletionSuccess'
import SignatureCanvas from 'react-signature-canvas'
import {
  ChildEnrolmentSteps,
  createEducatorSchemas,
  inits,
  mergeDeep,
} from '../core/ChildEnrolmentHelpers'
import {useMutation, useQuery} from 'react-query'
import {createChild, getChildByID, updateChild, getChildForm} from '../core/_request'
import {useParams} from 'react-router-dom'
import {ICreateEducator} from '../core/_models'
import {ChildInformationForm} from './steps/ChildInformation'
import {ParentGuardianForm} from './steps/ParentGuardiansForm'
import {ChildNominees} from './steps/ChildNominees'
import {ChildHealthInfo} from './steps/ChildHealthInfo'
import {ChildAuthorizations} from './steps/ChildAuthorizations'
import {ChildImmunizationForm} from './steps/ChildImmunizationForm'
import {ChildSchoolInfo} from './steps/ChildSchoolInfo'
import {StepperAsideMenu} from '../../../common/StepperAsideMenu'
import {ChildSchedule} from './steps/ChildSchdule'
import {EducatorFeesAndRelationship} from './steps/FeesAndRelationship'
import {useAuth} from '../../../../auth'
import {FocusError} from '../../../common/FocusError'

export const EducatorEditForm = () => {
  const {id} = useParams()
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const query: any = useQuery(['childData', id], () => getChildByID(id as string), {
    enabled: !!id, // Only execute the query if the ID is defined and not null
  })

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper: any = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createEducatorSchemas[0])
  const [initValues, setInitValues] = useState<any>(inits[0])
  const [childFormValues, setChildFormValues] = useState<ICreateEducator>()
  const createMutation: any = useMutation(createChild)
  const updateMutation: any = useMutation(updateChild)
  const signatureRef: RefObject<SignatureCanvas> = useRef(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    })
  }, [stepper?.current?.currentStepIndex])

  useEffect(() => {
    if (!query.isLoading && query?.isSuccess) {
      setChildFormValues(query?.data)
      if (query?.data?.signature) {
        signatureRef?.current?.fromDataURL(query?.data?.signature)
      }
      // setInitValues({basicInformation: query?.data?.basicInformation})
    }
  }, [query.isLoading])

  const updateStepperWithData = () => {
    setCurrentSchema(createEducatorSchemas[stepper.current.currentStepIndex])
    setInitValues(inits[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper?.current?.goNext()
    } else {
      stepper.current.goto(1)
      // actions.resetForm()
    }
    if (!childFormValues?._id) {
      navigate(`/child/edit/${createMutation?.data?._id}`)
      createMutation?.data && setChildFormValues(createMutation?.data)
    } else {
      updateMutation?.data && setChildFormValues(updateMutation?.data)
    }
  }

  useEffect(() => {
    if (!updateMutation?.isLoading && updateMutation?.isSuccess) {
      updateStepperWithData()
    }
  }, [updateMutation?.isSuccess])

  useEffect(() => {
    if (!createMutation?.isLoading && createMutation?.isSuccess) {
      updateStepperWithData()
    }
  }, [createMutation?.isSuccess])

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createEducatorSchemas[stepper?.current?.currentStepIndex - 1])
    setInitValues(inits[stepper?.current?.currentStepIndex - 1])
  }

  const submitStep = async (values: any, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex + 1 === stepper.current.totatStepsNumber) {
      values.status = 'Pending'
    }

    values._id
      ? await updateMutation.mutate({
          values: {...values, email: currentUser?.email},
          _id: values._id,
        })
      : await createMutation.mutate({...values, email: currentUser?.email})
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      <StepperAsideMenu steps={ChildEnrolmentSteps} />

      <div className='d-flex flex-row-fluid bg-body rounded'>
        <Formik
          validationSchema={currentSchema}
          enableReinitialize
          initialValues={mergeDeep(initValues, childFormValues)}
          onSubmit={submitStep}
        >
          {({values, errors, setFieldValue}) => (
            <Form className='py-20 w-100 px-9' noValidate id='kt_create_account_form'>
              {createMutation.isError && (
                <div>
                  <div className='alert alert-danger'>
                    {createMutation.error?.response?.data?.message ||
                      createMutation.error?.response?.data?.message[0] ||
                      ''}
                  </div>
                </div>
              )}
              <div className='current' data-kt-stepper-element='content'>
                <ChildInformationForm values={values} setFieldValue={setFieldValue} />
              </div>
              <div data-kt-stepper-element='content'>
                <ParentGuardianForm values={values} setFieldValue={setFieldValue} />
              </div>

              <div data-kt-stepper-element='content'>
                <ChildNominees values={values} setFieldValue={setFieldValue} />
              </div>

              <div data-kt-stepper-element='content'>
                <ChildHealthInfo values={values} setFieldValue={setFieldValue} />
              </div>
              <div data-kt-stepper-element='content'>
                <ChildImmunizationForm values={values} setFieldValue={setFieldValue} />
              </div>

              <div data-kt-stepper-element='content'>
                <ChildAuthorizations values={values} setFieldValue={setFieldValue} />
              </div>

              <div data-kt-stepper-element='content'>
                <ParentEducatorAgreementForm values={values} setFieldValue={setFieldValue} />
              </div>
              <div data-kt-stepper-element='content'>
                <ChildSchoolInfo values={values} setFieldValue={setFieldValue} />
              </div>
              <div data-kt-stepper-element='content'>
                <ChildSchedule values={values} setFieldValue={setFieldValue} errors={errors} />
              </div>

              <div data-kt-stepper-element='content'>
                <EducatorFeesAndRelationship values={values} setFieldValue={setFieldValue} />
              </div>
              <div data-kt-stepper-element='content'>
                <EducatorCompletionSuccess />
              </div>
              <div
                className={`clearfix ${
                  stepper?.current?.totatStepsNumber === stepper?.current?.currentStepIndex
                    ? 'd-none'
                    : ''
                }`}
              >
                <Field name='signature'>
                  {({field}: any) => (
                    <div className=' float-end clearfix'>
                      <SignatureCanvas
                        ref={signatureRef}
                        canvasProps={{
                          width: 400,
                          height: 200,
                          className: 'sigCanvas border border-3 border-secondary rounded',
                        }}
                        {...field}
                        onEnd={() => {
                          setFieldValue('signature', signatureRef?.current?.toDataURL())
                        }}
                      />
                      <button
                        onClick={() => {
                          signatureRef?.current?.clear()
                          setFieldValue('signature', null)
                        }}
                        className='btn btn-sm btn-light-danger d-block'
                      >
                        Clear
                      </button>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='signature' />
                      </div>
                    </div>
                  )}
                </Field>
                <div className=' float-end'></div>
              </div>
              <FocusError />

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className={`btn btn-lg btn-light-primary me-3 ${
                      stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber
                        ? 'd-none'
                        : ''
                    }`}
                    data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    Back
                  </button>
                </div>

                <div>
                  <button
                    type='submit'
                    className={`btn btn-lg btn-primary me-3 ${
                      stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber
                        ? 'd-none'
                        : ''
                    }`}
                  >
                    <span className='indicator-label'>
                      {stepper.current?.currentStepIndex !==
                        stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                      {stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber! - 1 && 'Got It'}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
