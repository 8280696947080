/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {ErrorMessage, Field, useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import 'yup-phone-lite'
import {PasswordField} from '../../apps/common/PasswordField'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  role: '',
  centrelinkRecord: '',
  relationWithChild: '',
  hasEducator: '',
  educatorName: '',
  contact: '',
}

const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('First name is required')
    .matches(/^[a-zA-Z0-9\s]+$/, 'First name should only contain letters and numbers'),
  lastName: Yup.string()
    .trim()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Last name is required')
    .matches(/^[a-zA-Z0-9\s]+$/, 'Last name should only contain letters and numbers'),
  role: Yup.string().label('Role').required('Please select any option'),
  email: Yup.string()
    .trim()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),

  password: Yup.string()
    .max(50, 'Maximum 50 characters')
    .required('Password is required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      'Password must contain upper and lowercase letters and at least one number'
    ),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),

  contact: Yup.string().phone('AU', 'Contact Number is invalid').required('Contact is required'),
  hasEducator: Yup.string().when(['role'], {
    is: (role: string) => role === 'parent',
    then: Yup.string().required('Please select the option'),
  }),
  centrelinkRecord: Yup.string().when(['role'], {
    is: (role: string) => role === 'parent',
    then: Yup.string().required('Please select the option'),
  }),
  relationWithChild: Yup.string().when(['role'], {
    is: (role: string) => role === 'parent',
    then: Yup.string().required('Please select the option'),
  }),
  educatorName: Yup.string().when(['hasEducator', 'role'], {
    is: (hasEducator: string, role: string) => hasEducator === 'true' && role === 'parent',
    then: Yup.string()
      .trim()
      .required('Educator name is required')
      .min(3, 'Minimum 3 characters')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Educator name should only contain letters and numbers'),
  }),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [success, setSuccess] = useState(false)
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        const {data: auth} = await register(
          values.email,
          values.firstName,
          values.lastName,
          values.password,
          values.confirmPassword,
          values.role,
          values.role === 'parent' ? values.hasEducator : 'false',
          values.role === 'parent' ? values.educatorName : '',
          values.role === 'parent' ? values.centrelinkRecord : '',
          values.role === 'parent' ? values.relationWithChild : '',
          values.contact
        )
        setSuccess(true)
        resetForm()
        setLoading(false)
        // saveAuth(auth)
        // await getUserByToken(auth.accessToken)
        // setCurrentUser(auth.user)

        // values.role === 'parent' ? navigate('/child') : navigate('/educator')
      } catch (error: any) {
        saveAuth(undefined)
        setStatus(error.response.data.message)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'></div>
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {success && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Sent verification mail. Please check your email</div>
        </div>
      )}

      {/* begin::Form group Role */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 required d-block'>
          I want to register as{' '}
        </label>
        <div className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='radio'
            {...formik.getFieldProps('role')}
            id='flexRadioDefault1'
            value='educator'
          />
          <label className='form-check-label' htmlFor='flexRadioDefault1'>
            An Educator
          </label>
        </div>
        <div className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='radio'
            id='flexRadioDefault2'
            {...formik.getFieldProps('role')}
            value='parent'
          />
          <label className='form-check-label' htmlFor='flexRadioDefault2'>
            Parent
          </label>
        </div>
        {formik.touched.role && formik.errors.role && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.role}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 required'>First name</label>
        <input
          placeholder='First name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstName')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.firstName && formik.errors.firstName,
            },
            {
              'is-valid': formik.touched.firstName && !formik.errors.firstName,
            }
          )}
        />
        {formik.touched.firstName && formik.errors.firstName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6 required'>Last name</label>
        <input
          placeholder='Last name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastName')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.lastName && formik.errors.lastName,
            },
            {
              'is-valid': formik.touched.lastName && !formik.errors.lastName,
            }
          )}
        />
        {formik.touched.lastName && formik.errors.lastName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastName}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Contact</label>
        <input
          placeholder='Contact'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('contact')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.contact && formik.errors.contact,
            },
            {
              'is-valid': formik.touched.contact && !formik.errors.contact,
            }
          )}
        />
        {formik.touched.contact && formik.errors.contact && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.contact}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <PasswordField
            formik={formik}
            label={'Password'}
            field={'password'}
            classes={'form-control bg-transparent'}
            labelClass={'form-label fw-bolder text-dark fs-6 required'}
          />

          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>

      <div className='fv-row mb-5'>
        <PasswordField
          formik={formik}
          label={'Confirm Password'}
          field={'confirmPassword'}
          classes={'form-control bg-transparent'}
          labelClass={'form-label fw-bolder text-dark fs-6 required'}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group Role */}
      {formik.values?.role === 'parent' && (
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6 required d-block'>
            Child record under centrelink{' '}
          </label>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              {...formik.getFieldProps('centrelinkRecord')}
              id='centerlinkref1'
              value='mother'
            />
            <label className='form-check-label' htmlFor='centerlinkref1'>
              Mother
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              {...formik.getFieldProps('centrelinkRecord')}
              id='centerlinkref2'
              value='father'
            />
            <label className='form-check-label' htmlFor='centerlinkref2'>
              Father
            </label>
          </div>

          {formik.touched.centrelinkRecord && formik.errors.centrelinkRecord && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.centrelinkRecord}</span>
              </div>
            </div>
          )}
        </div>
      )}
      {/* end::Form group */}

      {/* begin::Form group Role */}
      {formik.values?.role === 'parent' && (
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6 required d-block'>
            Your relation to the child{' '}
          </label>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              {...formik.getFieldProps('relationWithChild')}
              id='relationWithChildref1'
              value='mother'
            />
            <label className='form-check-label' htmlFor='relationWithChildref1'>
              Mother
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              {...formik.getFieldProps('relationWithChild')}
              id='relationWithChildref2'
              value='father'
            />
            <label className='form-check-label' htmlFor='relationWithChildref2'>
              Father
            </label>
          </div>
          {formik.touched.relationWithChild && formik.errors.relationWithChild && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.relationWithChild}</span>
              </div>
            </div>
          )}
        </div>
      )}
      {/* end::Form group */}
      {formik.values?.role === 'parent' && (
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>
            Do you have any Educator in mind?
          </label>
          <select
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('hasEducator')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.hasEducator && formik.errors.hasEducator,
              },
              {
                'is-valid': formik.touched.hasEducator && !formik.errors.hasEducator,
              }
            )}
          >
            <option value={''}>Select</option>
            <option value={'true'}>Yes</option>
            <option value={'false'}>No</option>
          </select>
          {formik.touched.hasEducator && formik.errors.hasEducator && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.hasEducator}</span>
              </div>
            </div>
          )}
        </div>
      )}

      {/* begin::Form group Educator Name */}
      {formik.values?.hasEducator === 'true' && formik.values?.role === 'parent' && (
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>First name</label>
          <input
            placeholder='Educator name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('educatorName')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.educatorName && formik.errors.educatorName,
              },
              {
                'is-valid': formik.touched.educatorName && !formik.errors.educatorName,
              }
            )}
          />
          {formik.touched.educatorName && formik.errors.educatorName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.educatorName}</span>
              </div>
            </div>
          )}
        </div>
      )}
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
