import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {ICreateEducator, User} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const EDUCATOR_URL = `${API_URL}/educators`

const createEducator = (formInfo: ICreateEducator): Promise<ICreateEducator | undefined> => {
  return axios
    .post(`${EDUCATOR_URL}`, formInfo)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const updateEducator = (formInfo: ICreateEducator): Promise<ICreateEducator | undefined> => {
  return axios
    .patch(`${EDUCATOR_URL}/${formInfo._id}`, formInfo.values)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const getEducatorByID = (id: string): Promise<ICreateEducator | undefined> => {
  return axios
    .get(`${EDUCATOR_URL}/${id}`)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const getEducatorForm = (): Promise<ICreateEducator | undefined> => {
  return axios
    .get(`${EDUCATOR_URL}/get-educator-form`)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

export {createEducator, getEducatorByID, updateEducator, getEducatorForm}
