import {connect, FormikContextType} from 'formik'
import {Component} from 'react'

interface IProps {
  formik: FormikContextType<any>
}

class ErrorFocusInternal extends Component<IProps> {
  public componentDidUpdate(prevProps: IProps) {
    const {isSubmitting, isValidating, errors} = prevProps.formik
    const keyify: any = (obj: any, prefix = '') =>
      Object.keys(obj).reduce((res: any, el) => {
        if (Array.isArray(obj[el])) {
          return res
        } else if (typeof obj[el] === 'object' && obj[el] !== null) {
          return [...res, ...keyify(obj[el], prefix + el + '.')]
        } else {
          return [...res, prefix + el]
        }
      }, [])
    const keys = keyify(errors)

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[name="${keys[0]}"]`
      const errorElement = document.querySelector(selector) as HTMLElement
      if (errorElement) {
        errorElement.focus()
      }
    }
  }

  public render = () => null
}

export const FocusError = connect<{}>(ErrorFocusInternal)
