import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate, useNavigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import ChildEnrolmentPage from '../modules/apps/child-enrolment/ChildEnrolementPage'
import {useAuth} from '../modules/auth'
import {ContactPage} from '../modules/contact/ContactPage'
import {ApprovalPage} from '../modules/approval-needed/ContactPage'

const PrivateRoutes = () => {
  const {currentUser, logout} = useAuth()

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const EducatorsPage = lazy(() => import('../modules/apps/educator/EducatorsPage'))
  const allowRoles = ['educator', 'parent']

  useEffect(() => {
    if (!allowRoles.includes(currentUser?.role?.toLowerCase() as string)) {
      logout()
    }
  }, [])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {currentUser?.status !== 'Pending' && (
          <Route
            path='/*'
            element={<Navigate to={currentUser?.role === 'educator' ? '/educator' : '/child'} />}
          />
        )}
        {currentUser?.status === 'Pending' && (
          <Route path='/*' element={<Navigate to='/contact-soon' />} />
        )}

        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}

        {currentUser?.status !== 'Pending' && currentUser?.role === 'educator' && (
          <Route
            path='educator/*'
            element={
              <SuspensedView>
                <EducatorsPage />
              </SuspensedView>
            }
          />
        )}
        {currentUser?.status !== 'Pending' && currentUser?.role === 'parent' && (
          <Route
            path='child/*'
            element={
              <SuspensedView>
                <ChildEnrolmentPage />
              </SuspensedView>
            }
          />
        )}

        {currentUser?.status === 'Pending' && (
          <Route path='contact-soon/*' element={<ContactPage />} />
        )}

        <Route path='approval-request/*' element={<ApprovalPage />} />

        {/* Lazy Modules */}
        <Route
          path='/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
