/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import {useAuth} from '../../../../../auth'
import Collapsible from '../../../../common/Collapsible'
import ChildHeader from '../../../../common/ChildFormHeader'
import {AddressDetailInfo} from '../../../../common/AddressDetailInfo'
import {TimeRangePicker} from '../../../../common/TimeRangePicker'
import DatePicker from '../../../../common/DatePicker'

const ChildSchoolInfo: FC<any> = ({values, setFieldValue}) => {
  const {currentUser} = useAuth()

  return (
    <div className='w-100'>
      <ChildHeader />
      <div className='pb-10 pb-lg-15'>
        <div className='shadow-sm'>
          <div className='d-flex flex-column p-9'>
            <Collapsible
              title={'Details of school, kindergarten or childcare child attends'}
              id='school-info'
            >
              <div className='fv-row'>
                <h3>At this time of enrolment</h3>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5  mb-2'>
                    <span className='required'>Child attends</span>
                  </label>
                  <div className='row'>
                    <span className=' fs-5  m-2 '>
                      <Field
                        type='radio'
                        className='me-2'
                        name='childSchool.attends'
                        value='child care'
                      />
                      <label>Child care</label>
                    </span>
                  </div>
                  <div className='row'>
                    <span className=' fs-5  m-2 '>
                      <Field
                        type='radio'
                        className='me-2'
                        name='childSchool.attends'
                        value='kindergarten'
                      />
                      <label>Kindergarten</label>
                    </span>
                  </div>
                  <div className='row'>
                    <span className=' fs-5  m-2 '>
                      <Field
                        type='radio'
                        className='me-2'
                        name='childSchool.attends'
                        value='school'
                      />
                      <label>School</label>
                    </span>
                  </div>
                  <div className='row'>
                    <span className=' fs-5  m-2 '>
                      <Field
                        type='radio'
                        className='me-2'
                        name='childSchool.attends'
                        value='none'
                      />
                      <label>None</label>
                    </span>
                  </div>

                  <div className='text-danger mt-2'>
                    <ErrorMessage name='childSchool.attends' />
                  </div>
                </div>
              </div>
              {values?.childSchool?.attends && values?.childSchool?.attends !== 'none' && (
                <>
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Name of child care, kindergarten or school</span>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name='childSchool.name'
                      placeholder='School Name'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='childSchool.name' />
                    </div>
                  </div>
                  <AddressDetailInfo
                    values={values}
                    setFieldValue={setFieldValue}
                    field='childSchool.address'
                  />
                  <div className='fv-row mb-10'>
                    <div className='row'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className='required'>List term dates</span>
                      </label>
                      <div className='col-md-3 fv-row mb-5'>
                        <DatePicker
                          name='childSchool.termsDate[0]'
                          selected={
                            (values?.childSchool?.termsDate?.[0] &&
                              new Date(values?.childSchool?.termsDate?.[0])) ||
                            null
                          }
                          placeholderText='Select Month'
                          dateFormat='MM/yyyy'
                          showMonthYearPicker
                          showFullMonthYearPicker
                          onChange={(value: any) =>
                            setFieldValue('childSchool.termsDate[0]', value)
                          }
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='childSchool.termsDate[0]' />
                        </div>
                      </div>

                      <div className='col-md-3 fv-row mb-5'>
                        <DatePicker
                          name='childSchool.termsDate[1]'
                          selected={
                            (values?.childSchool?.termsDate?.[1] &&
                              new Date(values?.childSchool?.termsDate?.[1])) ||
                            null
                          }
                          placeholderText='Select Month'
                          dateFormat='MM/yyyy'
                          showMonthYearPicker
                          showFullMonthYearPicker
                          onChange={(value: any) =>
                            setFieldValue('childSchool.termsDate[1]', value)
                          }
                        />

                        <div className='text-danger mt-2'>
                          <ErrorMessage name='childSchool.termsDate[1]' />
                        </div>
                      </div>
                      <div className='col-md-3 fv-row mb-5'>
                        <DatePicker
                          name='childSchool.termsDate[2]'
                          selected={
                            (values?.childSchool?.termsDate?.[2] &&
                              new Date(values?.childSchool?.termsDate?.[2])) ||
                            null
                          }
                          placeholderText='Select Month'
                          dateFormat='MM/yyyy'
                          showMonthYearPicker
                          showFullMonthYearPicker
                          onChange={(value: any) =>
                            setFieldValue('childSchool.termsDate[2]', value)
                          }
                        />

                        <div className='text-danger mt-2'>
                          <ErrorMessage name='childSchool.termsDate[2]' />
                        </div>
                      </div>
                      <div className='col-md-3 fv-row mb-5'>
                        <DatePicker
                          name='childSchool.termsDate[3]'
                          selected={
                            (values?.childSchool?.termsDate?.[3] &&
                              new Date(values?.childSchool?.termsDate?.[3])) ||
                            null
                          }
                          placeholderText='Select Month'
                          dateFormat='MM/yyyy'
                          showMonthYearPicker
                          showFullMonthYearPicker
                          onChange={(value: any) =>
                            setFieldValue('childSchool.termsDate[3]', value)
                          }
                        />

                        <div className='text-danger mt-2'>
                          <ErrorMessage name='childSchool.termsDate[3]' />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Collapsible>

            {values?.childSchool?.attends && values?.childSchool?.attends !== 'none' && (
              <>
                <Collapsible title={'Pre school hours assistance'} id='pre-school-info'>
                  <p>
                    Government assistance for before school care is available for a block of two
                    hours only which is the two hours ending when school starts.
                  </p>
                  <div className='fv-row'>
                    <div className='row'>
                      <div className='col-md-6 fv-row mb-5'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Your chid school starts at</span>
                        </label>

                        <DatePicker
                          name='childSchool.startsAt'
                          className='form-control form-control-lg form-control-solid'
                          selected={
                            (values?.childSchool?.startsAt &&
                              new Date(values?.childSchool?.startsAt)) ||
                            null
                          }
                          placeholderText='School Start At'
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption='Time'
                          dateFormat='h:mm a'
                          minTime={new Date(0, 0, 0, 7, 0)}
                          maxTime={new Date(0, 0, 0, 19, 0)}
                          onChange={(value: any) => setFieldValue('childSchool.startsAt', value)}
                        />

                        <div className='text-danger mt-2'>
                          <ErrorMessage name='childSchool.startsAt' />
                        </div>
                      </div>

                      <div className='col-md-6 fv-row mb-5'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Before school care will be</span>
                        </label>
                        <TimeRangePicker
                          setFieldValue={setFieldValue}
                          field={'childAssistance.beforeSchool'}
                          value={values?.childAssistance?.beforeSchool}
                          ErrorMessage={ErrorMessage}
                        />
                      </div>
                    </div>
                  </div>
                </Collapsible>
                <Collapsible title={'Post school hours assistance'} id='post-school-info'>
                  <p>
                    Government assistance for after school care is available for a block of three
                    and a half hours only which is the three and a half hours starting when school
                    ends.
                  </p>
                  <div className='fv-row'>
                    <div className='row'>
                      <div className='col-md-6 fv-row mb-5'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Your chid school ends at</span>
                        </label>

                        <DatePicker
                          name='childSchool.endsAt'
                          className='form-control form-control-lg form-control-solid'
                          selected={
                            (values?.childSchool?.endsAt &&
                              new Date(values?.childSchool?.endsAt)) ||
                            null
                          }
                          placeholderText='School Ends At'
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption='Time'
                          dateFormat='h:mm a'
                          minTime={new Date(0, 0, 0, 7, 0)}
                          maxTime={new Date(0, 0, 0, 19, 0)}
                          onChange={(value: any) => setFieldValue('childSchool.endsAt', value)}
                        />

                        <div className='text-danger mt-2'>
                          <ErrorMessage name='childSchool.endsAt' />
                        </div>
                      </div>

                      <div className='col-md-6 fv-row mb-5'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>After school care will be</span>
                        </label>
                        <TimeRangePicker
                          setFieldValue={setFieldValue}
                          field={'childAssistance.afterSchool'}
                          value={values?.childAssistance?.afterSchool}
                          ErrorMessage={ErrorMessage}
                        />
                      </div>
                    </div>
                  </div>
                </Collapsible>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {ChildSchoolInfo}
