import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../../auth'

const ContactSoon: FC = () => {
  const {logout} = useAuth()
  return (
    <>
      <div>
        {/* begin::Title */}
        <div className='mb-3'>
          <img
            src={toAbsoluteUrl('/media/icons8-verification.svg')}
            className='mw-50 mh-100px theme-light-show'
            alt=''
          />
        </div>
        <h1 className='fw-bolder fs-1hx text-gray-900 mb-4 mt-8'>Verification in progress...</h1>
        {/* end::Title */}
        {/* begin::Text */}
        <div className='fw-semibold fs-6 text-gray-500 mb-7'>
          Our team will contact you for verification.
          <br /> You will be able to login after successful verification.
        </div>
        {/* end::Text */}
        {/* begin::Illustration */}
        {/* end::Illustration */}
        {/* begin::Link */}
        <div className='mb-0'>
          <button className='btn btn-sm btn-primary' onClick={logout}>
            Logout
            <KTSVG path='/media/icons/duotune/arrows/arr076.svg' />
          </button>
        </div>
        {/* end::Link */}
      </div>
    </>
  )
}

export {ContactSoon}
