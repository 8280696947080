import {ErrorMessage, Field, useField} from 'formik'
import React, {FC} from 'react'
import Select from 'react-select'
import {days} from '../child-enrolment/child-form/core/ChildEnrolmentHelpers'
import {KTSVG} from '../../../../_metronic/helpers'

interface Props {
  values: any
  arrayHelpers: any
  field: string
  setFieldValue: any
  preSchool?: boolean
  title?: string
}

interface Props {
  values: any
  arrayHelpers: any
  field: string
  setFieldValue: any
}

export const TransportationSchedule: FC<Props> = ({values, arrayHelpers, field, setFieldValue}) => {
  return (
    <>
      {values?.length > 0 &&
        values?.map((value: any, index: any) => (
          <table className='table table-responsive' key={index}>
            <tbody>
              <tr>
                <td data-label='Name'>
                  <div className=' w-100'>
                    <div className='row'>
                      <div className='col-md-10'>
                        <div className='row'>
                          <div className='col-md-4'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span className='required'>Day/s</span>
                            </label>
                            <Select
                              options={days}
                              placeholder='Select'
                              name={`${field}[${index}].days`}
                              className={`multi-select-container ${index === 0 ? 'mt-3' : 'mt-2'}`}
                              classNamePrefix='multi-select'
                              onChange={(e: any) => {
                                setFieldValue(
                                  `${field}[${index}].days`,
                                  e.map((day: any) => day.value)
                                )
                              }}
                              // value={days.filter((day: any) => value?.day == day.label)}

                              value={days.filter((day: any) => value?.days?.includes(day.value))}
                              closeMenuOnSelect={false}
                              isMulti
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}].days`} />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span className='required'>Reason for transportation </span>
                            </label>

                            <Field
                              className='form-control form-control-solid mb-2'
                              as='textarea'
                              rows={4}
                              placeholder={'Please enter details'}
                              name={`${field}[${index}].reason`}
                            ></Field>

                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}].reason`} />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span className='required'>Pick up location and Destination </span>
                            </label>

                            <Field
                              className='form-control form-control-solid mb-2'
                              as='textarea'
                              rows={4}
                              placeholder={'Please enter details'}
                              name={`${field}[${index}].pickupDestinationLocation`}
                            ></Field>

                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}].pickupDestinationLocation`} />
                            </div>
                          </div>
                        </div>
                        <div className='row mt-4'>
                          <div className='col-md-4'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span className='required'>
                                Approximate time and duration of transportation
                              </span>
                            </label>
                            <Field
                              name={`${field}[${index}].timeAndDuration`}
                              placeholder='Duration time'
                              className='form-control form-control-md form-control-solid'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}].timeAndDuration`} />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span className='required'>Method of transport</span>
                            </label>
                            <Field
                              as='select'
                              name={`${field}[${index}].transportType`}
                              className='form-select form-select-solid'
                            >
                              <option value=''>Select transport</option>
                              <option value='Car'>Car</option>
                              <option value='Mini Bus'>Mini Bus</option>
                              <option value='Walk'>Walk</option>
                            </Field>

                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}].transportType`} />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span className='required'>
                                Requirements for seat belts or safety restraints
                              </span>
                            </label>
                            <Field
                              as='select'
                              name={`${field}[${index}].safetyRequirements`}
                              className='form-select form-select-solid'
                            >
                              <option value=''>Select</option>
                              <option value='Yes'>Yes</option>
                              <option value='No'>No</option>
                            </Field>

                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}].safetyRequirements`} />
                            </div>
                          </div>
                        </div>
                        <div className='row mt-4'>
                          <div className='col-md-4'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span className='required'>No. of children</span>
                            </label>
                            <Field
                              name={`${field}[${index}].childrenCount`}
                              placeholder='No. of children'
                              type='number'
                              className='form-control form-control-md form-control-solid'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}].childrenCount`} />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span className='required'>
                                Supervising staff, educators or other adults{' '}
                              </span>
                            </label>

                            <Field
                              className='form-control form-control-solid mb-2'
                              as='textarea'
                              rows={4}
                              placeholder={'Please enter details'}
                              name={`${field}[${index}].supervisingStaff`}
                            ></Field>

                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}].supervisingStaff`} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-2 mt-10'>
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                          onClick={() => arrayHelpers.remove(index)}
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          title='Remove Item'
                          disabled={values.length > 1 ? false : true}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon svg-icon-3'
                          />
                        </button>
                        {values.length === index + 1 && (
                          <button
                            type='button'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Add New member'
                            onClick={() => {
                              if (values[0].hasOwnProperty('identificationNumber')) {
                                arrayHelpers.push({
                                  fullName: '',
                                  dob: '',
                                  policeCheckReport: '',
                                  identificationNumber: '',
                                  expiryDate: '',
                                  workingWithChildrenCheck: '',
                                })
                              } else {
                                arrayHelpers.push({
                                  fullName: '',
                                  dob: '',
                                  policeCheckReport: '',
                                })
                              }
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr013.svg'
                              className='svg-icon  svg-icon-3'
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        ))}
    </>
  )
}
