import {ErrorMessage, Field, useField} from 'formik'
import React, {FC} from 'react'
import {AddressDetailInfo} from './AddressDetailInfo'
import Collapsible from './Collapsible'
interface Props {
  values: any
  arrayHelpers: any
  field: string
  setFieldValue: any
}

export const NomineeForm: FC<Props> = ({values, arrayHelpers, field, setFieldValue}) => {
  return (
    <>
      {values?.length > 0 &&
        values?.map((value: any, index: any) => (
          <React.Fragment key={index}>
            <Collapsible
              title={`Nominee ${index + 1}`}
              id={`Nominee-${index + 1}`}
              arrayHelpers={arrayHelpers}
              values={values}
              index={index}
            >
              <table className='table table-responsive ' key={index}>
                <tbody>
                  <tr>
                    <td data-label='Name'>
                      <div className=' w-100'>
                        <div className='row'>
                          <div className='col-md-6'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span className='required'>Full name</span>
                            </label>
                            <Field
                              name={`${field}[${index}]fullName`}
                              placeholder='Full name'
                              className='form-control form-control-md form-control-solid'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}]fullName`} />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <label className='d-flex align-items-center fs-5  mb-2 row'>
                              <span className='required col-10'>Relation with Child</span>
                            </label>
                            <Field
                              as='select'
                              name={`${field}[${index}]relationWithChild`}
                              className='form-select form-select-solid'
                            >
                              <option value=''>Select Relationship</option>
                              <option value='Cousin'>Cousin</option>
                              <option value='German'>Niece / Nephew</option>
                              <option value='Grand Child'>Grand Child</option>
                              <option value='Other'>Other</option>
                            </Field>

                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}]relationWithChild`} />
                            </div>
                          </div>
                        </div>
                        <AddressDetailInfo
                          values={values}
                          setFieldValue={setFieldValue}
                          field={`${field}[${index}]address`}
                        />
                        <div className='row'>
                          <div className='col-md-6'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span>Home phone</span>
                            </label>
                            <Field
                              name={`${field}[${index}]homeContact`}
                              placeholder='Home phone'
                              className='form-control form-control-md form-control-solid'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}]homeContact`} />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span className='required'>Mobile phone</span>
                            </label>
                            <Field
                              name={`${field}[${index}]mobileContact`}
                              placeholder='Mobile phone'
                              className='form-control form-control-md form-control-solid'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}]mobileContact`} />
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-6'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span className='required'>Email address</span>
                            </label>
                            <Field
                              name={`${field}[${index}]email`}
                              placeholder='Email address'
                              className='form-control form-control-md form-control-solid'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}]email`} />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <label className='d-flex fs-5 fw-bold mb-2'>
                              <span>Other contact detail</span>
                            </label>
                            <Field
                              name={`${field}[${index}]otherContact`}
                              placeholder='Other contact detail'
                              className='form-control form-control-md form-control-solid'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name={`${field}[${index}]otherContact`} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Collapsible>
          </React.Fragment>
        ))}
    </>
  )
}
