/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import {ContactSoon} from './components/ContactSoon'
import {ContactLayout} from './ContactLayout'

const ApprovalPage = () => (
  <Routes>
    <Route element={<ContactLayout />}>
      <Route index element={<ContactSoon />} />
    </Route>
  </Routes>
)

export {ApprovalPage}
