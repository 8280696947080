import * as Yup from 'yup'

export const createEducatorSchemas = [
  Yup.object({
    parentEducatorAgreement: Yup.object({
      childrenNames: Yup.string().required('Child’s/Children’s Name/s are  required'),
      domesticPets: Yup.string().required('Domestic Pets Name/s are required'),
      expensesIncurred: Yup.string().required('Expenses Incurred Name/s are required'),
    }),
  }),
  Yup.object({
    educatorFeeSchedule: Yup.string(),
  }),
  Yup.object({
    careArrangement: Yup.string().trim().required('Please select an option above'),
    childSessionsInfo: Yup.object({
      schoolAge: Yup.array()
        .of(
          Yup.object({
            day: Yup.string().required('Please select day'),
            beforeSchoolCareTime: Yup.string().required('Please select time').nullable(true),
            afterSchoolCareTime: Yup.string().required('Please select time').nullable(true),
          })
        )
        .min(1),
      preSchoolAge: Yup.array()
        .of(
          Yup.object({
            day: Yup.string().required('Please select day'),
            careTime: Yup.string().required('Please select time').nullable(true),
          })
        )
        .min(1),
      schoolAgeHoliday: Yup.array()
        .of(
          Yup.object({
            day: Yup.string().required('Please select day'),
            careTime: Yup.string().required('Please select time').nullable(true),
          })
        )
        .min(1),
    }),
    feesDetails: Yup.object({
      standardFees: Yup.string().required('Standard fee is required'),
      nonStandardFees: Yup.string().required('Non Standard fee is required'),
      weekendFees: Yup.string().required('Weekend fee is required'),
      casualFees: Yup.string().required('Casual fee is required'),
      otherFees: Yup.string().required('Other fee is required'),
    }),
  }),
  Yup.object({
    statutoryDeclarationForm: Yup.string().required('Please upload file'),
  }),
  Yup.object({
    saturdayStatutoryDeclarationForm: Yup.string(),
  }),
  Yup.object({
    regularTransportation: Yup.object({
      park: Yup.object({
        childNames: Yup.string().trim().required('Child/ren names are required'),
        description: Yup.string().trim().required('Description are required'),
        proposedActivities: Yup.string().trim().required('Proposed activities are required'),
        childAdultRatio: Yup.string().trim().required('Child adult ratio is required'),
        medicationRequired: Yup.string().trim().required('Please select an option'),
        transportSchedule: Yup.array()
          .of(
            Yup.object({
              days: Yup.array().required('Please select day').min(1, 'Please select day'),
              reason: Yup.string().trim().required('Reason of transportation is required'),
              pickupDestinationLocation: Yup.string()
                .trim()
                .required('Pick up and destination is required'),
              timeAndDuration: Yup.string().trim().required('Time and duration is required'),
              transportType: Yup.string().trim().required('Please select an option'),
              childrenCount: Yup.string().trim().required('No. of children are required'),
              supervisingStaff: Yup.string()
                .trim()
                .required('Supervising staff detail is required'),
              safetyRequirements: Yup.string().trim().required('Please select an option'),
            })
          )
          .min(1),
      }),

      shopping: Yup.object({
        childNames: Yup.string().trim().required('Child/ren names are required'),
        description: Yup.string().trim().required('Description are required'),
        proposedActivities: Yup.string().trim().required('Proposed activities are required'),
        childAdultRatio: Yup.string().trim().required('Child adult ratio is required'),
        medicationRequired: Yup.string().trim().required('Please select an option'),
        transportSchedule: Yup.array()
          .of(
            Yup.object({
              days: Yup.array().required('Please select day').min(1, 'Please select day'),
              reason: Yup.string().trim().required('Reason of transportation is required'),
              pickupDestinationLocation: Yup.string()
                .trim()
                .required('Pick up and destination is required'),
              timeAndDuration: Yup.string().trim().required('Time and duration is required'),
              transportType: Yup.string().trim().required('Please select an option'),
              childrenCount: Yup.string().trim().required('No. of children are required'),
              supervisingStaff: Yup.string()
                .trim()
                .required('Supervising staff detail is required'),
              safetyRequirements: Yup.string().trim().required('Please select an option'),
            })
          )
          .min(1),
      }),

      library: Yup.object({
        childNames: Yup.string().trim().required('Child/ren names are required'),
        description: Yup.string().trim().required('Description are required'),
        proposedActivities: Yup.string().trim().required('Proposed activities are required'),
        childAdultRatio: Yup.string().trim().required('Child adult ratio is required'),
        medicationRequired: Yup.string().trim().required('Please select an option'),
        transportSchedule: Yup.array()
          .of(
            Yup.object({
              days: Yup.array().required('Please select day').min(1, 'Please select day'),
              reason: Yup.string().trim().required('Reason of transportation is required'),
              pickupDestinationLocation: Yup.string()
                .trim()
                .required('Pick up and destination is required'),
              timeAndDuration: Yup.string().trim().required('Time and duration is required'),
              transportType: Yup.string().trim().required('Please select an option'),
              childrenCount: Yup.string().trim().required('No. of children are required'),
              supervisingStaff: Yup.string()
                .trim()
                .required('Supervising staff detail is required'),
              safetyRequirements: Yup.string().trim().required('Please select an option'),
            })
          )
          .min(1),
      }),

      school: Yup.object({
        childNames: Yup.string().trim().required('Child/ren names are required'),
        description: Yup.string().trim().required('Description are required'),
        proposedActivities: Yup.string().trim().required('Proposed activities are required'),
        childAdultRatio: Yup.string().trim().required('Child adult ratio is required'),
        medicationRequired: Yup.string().trim().required('Please select an option'),
        transportSchedule: Yup.array()
          .of(
            Yup.object({
              days: Yup.array().required('Please select day').min(1, 'Please select day'),
              reason: Yup.string().trim().required('Reason of transportation is required'),
              pickupDestinationLocation: Yup.string()
                .trim()
                .required('Pick up and destination is required'),
              timeAndDuration: Yup.string().trim().required('Time and duration is required'),
              transportType: Yup.string().trim().required('Please select an option'),
              childrenCount: Yup.string().trim().required('No. of children are required'),
              supervisingStaff: Yup.string()
                .trim()
                .required('Supervising staff detail is required'),
              safetyRequirements: Yup.string().trim().required('Please select an option'),
            })
          )
          .min(1),
      }),

      otherLocation: Yup.object({
        childNames: Yup.string().trim().required('Child/ren names are required'),
        description: Yup.string().trim().required('Description are required'),
        proposedActivities: Yup.string().trim().required('Proposed activities are required'),
        childAdultRatio: Yup.string().trim().required('Child adult ratio is required'),
        medicationRequired: Yup.string().trim().required('Please select an option'),
        transportSchedule: Yup.array()
          .of(
            Yup.object({
              days: Yup.array().required('Please select day').min(1, 'Please select day'),
              reason: Yup.string().trim().required('Reason of transportation is required'),
              pickupDestinationLocation: Yup.string()
                .trim()
                .required('Pick up and destination is required'),
              timeAndDuration: Yup.string().trim().required('Time and duration is required'),
              transportType: Yup.string().trim().required('Please select an option'),
              childrenCount: Yup.string().trim().required('No. of children are required'),
              supervisingStaff: Yup.string()
                .trim()
                .required('Supervising staff detail is required'),
              safetyRequirements: Yup.string().trim().required('Please select an option'),
            })
          )
          .min(1),
      }),
    }),
  }),
]

export const childPostApprovalKeyData: {keys: string[]}[] = [
  {
    keys: ['parentEducatorAgreement'],
  },
  {
    keys: [''],
  },
  {
    keys: ['careArrangement', 'childSessionsInfo', 'feesDetails'],
  },
  {
    keys: ['statutoryDeclarationForm'],
  },
  {
    keys: ['saturdayStatutoryDeclarationForm'],
  },
  {
    keys: ['regularTransportation'],
  },
]

export const inits: any = [
  {
    parentEducatorAgreement: {
      childrenNames: '',
      domesticPets: '',
      expensesIncurred: '',
    },
  },
  {
    educatorFeeSchedule: '',
  },
  {
    careArrangement: '',
    childSessionsInfo: {
      schoolAge: [{day: '', beforeSchoolCareTime: '', afterSchoolCareTime: ''}],
      preSchoolAge: [{day: '', careTime: ''}],
      schoolAgeHoliday: [{day: '', careTime: ''}],
    },
    feesDetails: {
      standardFees: '',
      nonStandardFees: '',
      weekendFees: '',
      casualFees: '',
      otherFees: '',
    },
  },
  {
    statutoryDeclarationForm: '',
  },
  {
    saturdayStatutoryDeclarationForm: '',
  },
  {
    regularTransportation: {
      park: {
        childNames: '',
        description: '',
        proposedActivities: '',
        childAdultRatio: '',
        medicationRequired: '',
        transportSchedule: [
          {
            days: [],
            reason: '',
            pickupDestinationLocation: '',
            safetyRequirements: '',
            timeAndDuration: '',
            supervisingStaff: '',
            transportType: '',
            childrenCount: '',
          },
        ],
      },
      shopping: {
        childNames: '',
        description: '',
        proposedActivities: '',
        childAdultRatio: '',
        medicationRequired: '',
        transportSchedule: [
          {
            days: [],
            reason: '',
            pickupDestinationLocation: '',
            safetyRequirements: '',
            timeAndDuration: '',
            supervisingStaff: '',
            transportType: '',
            childrenCount: '',
          },
        ],
      },
      library: {
        childNames: '',
        description: '',
        proposedActivities: '',
        childAdultRatio: '',
        medicationRequired: '',
        transportSchedule: [
          {
            days: [],
            reason: '',
            pickupDestinationLocation: '',
            safetyRequirements: '',
            timeAndDuration: '',
            supervisingStaff: '',
            transportType: '',
            childrenCount: '',
          },
        ],
      },
      school: {
        childNames: '',
        description: '',
        proposedActivities: '',
        childAdultRatio: '',
        medicationRequired: '',
        transportSchedule: [
          {
            days: [],
            reason: '',
            pickupDestinationLocation: '',
            safetyRequirements: '',
            timeAndDuration: '',
            supervisingStaff: '',
            transportType: '',
            childrenCount: '',
          },
        ],
      },
      otherLocation: {
        childNames: '',
        description: '',
        proposedActivities: '',
        childAdultRatio: '',
        medicationRequired: '',
        transportSchedule: [
          {
            days: [],
            reason: '',
            pickupDestinationLocation: '',
            safetyRequirements: '',
            timeAndDuration: '',
            supervisingStaff: '',
            transportType: '',
            childrenCount: '',
          },
        ],
      },
    },
  },
]

export const days = [
  {
    value: 'Monday',
    label: 'Monday',
  },
  {
    value: 'Tuesday',
    label: 'Tuesday',
  },
  {
    value: 'Wednesday',
    label: 'Wednesday',
  },
  {
    value: 'Thursday',
    label: 'Thursday',
  },
  {
    value: 'Friday',
    label: 'Friday',
  },
  {
    value: 'Saturday',
    label: 'Saturday',
  },
]

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const mergeDeep: any = (target: any, ...sources: any) => {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, {[key]: {}})
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, {[key]: source[key]})
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export const regularTransportationArr = [
  {
    heading: 'Park',
    id: 'park',
    fields: [
      {
        label: "Child's Name",
        name: 'regularTransportation.park.childNames',
        type: 'textarea',
        placeholder: "Child's Name",
      },
      {
        label: 'Description',
        name: 'regularTransportation.park.description',
        type: 'textarea',
        placeholder: 'Description',
      },

      {
        label: 'Proposed activities',
        name: 'regularTransportation.park.proposedActivities',
        type: 'textarea',
        placeholder: 'Proposed activities',
      },
      {
        label: 'Ratio',
        name: 'regularTransportation.park.childAdultRatio',
        type: 'text',
        placeholder: 'Child adult ratio',
      },
    ],
  },
  {
    heading: 'Shopping centre',
    id: 'shopping',
    fields: [
      {
        label: "Child's Name",
        name: 'regularTransportation.shopping.childNames',
        type: 'textarea',
        placeholder: "Child's Name",
      },
      {
        label: 'Description',
        name: 'regularTransportation.shopping.description',
        type: 'textarea',
        placeholder: 'Description',
      },
      {
        label: 'Proposed activities',
        name: 'regularTransportation.shopping.proposedActivities',
        type: 'textarea',
        placeholder: 'Proposed activities',
      },
      {
        label: 'Ratio',
        name: 'regularTransportation.shopping.childAdultRatio',
        type: 'text',
        placeholder: 'Child adult ratio',
      },
    ],
  },
  {
    heading: 'Library',
    id: 'library',
    fields: [
      {
        label: "Child's Name",
        name: 'regularTransportation.library.childNames',
        type: 'textarea',
        placeholder: "Child's Name",
      },
      {
        label: 'Description',
        name: 'regularTransportation.library.description',
        type: 'textarea',
        placeholder: 'Description',
      },
      {
        label: 'Proposed activities',
        name: 'regularTransportation.library.proposedActivities',
        type: 'textarea',
        placeholder: 'Proposed activities',
      },
      {
        label: 'Ratio',
        name: 'regularTransportation.library.childAdultRatio',
        type: 'text',
        placeholder: 'Child adult ratio',
      },
    ],
  },
  {
    heading: 'School',
    id: 'school',
    fields: [
      {
        label: "Child's Name",
        name: 'regularTransportation.school.childNames',
        type: 'textarea',
        placeholder: "Child's Name",
      },
      {
        label: 'Description',
        name: 'regularTransportation.school.description',
        type: 'textarea',
        placeholder: 'Description',
      },
      {
        label: 'Proposed activities',
        name: 'regularTransportation.school.proposedActivities',
        type: 'textarea',
        placeholder: 'Proposed activities',
      },
      {
        label: 'Ratio',
        name: 'regularTransportation.school.childAdultRatio',
        type: 'text',
        placeholder: 'Child adult ratio',
      },
    ],
  },

  {
    heading: 'Other locations',
    id: 'otherLocation',
    fields: [
      {
        label: "Child's Name",
        name: 'regularTransportation.otherLocation.childNames',
        type: 'textarea',
        placeholder: "Child's Name",
      },
      {
        label: 'Description',
        name: 'regularTransportation.otherLocation.description',
        type: 'textarea',
        placeholder: 'Description',
      },
      {
        label: 'Proposed activities',
        name: 'regularTransportation.otherLocation.proposedActivities',
        type: 'textarea',
        placeholder: 'Proposed activities',
      },
      {
        label: 'Ratio',
        name: 'regularTransportation.otherLocation.childAdultRatio',
        type: 'text',
        placeholder: 'Child adult ratio',
      },
    ],
  },
]
