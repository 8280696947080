import {useIntl} from 'react-intl'
import {useAuth} from '../../../../../app/modules/auth'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../helpers'
import {getChildForm} from '../../../../../app/modules/apps/child-enrolment/child-form/core/_request'
import {getEducatorForm} from '../../../../../app/modules/apps/educator/educator-form/core/_request'
import {useQuery} from 'react-query'
import {useEffect, useState} from 'react'

export function MenuInner() {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const intl = useIntl()
  const getUserForm =
    currentUser?.role?.toLowerCase() === 'educator' ? getEducatorForm : getChildForm

  const userFormQuery: any = useQuery({
    queryKey: ['getUserForm'],
    queryFn: getUserForm,
    retry: 2, // Will retry failed requests 2 times before displaying an error
  })
  const [homeRoute, setHomeRoute] = useState(
    currentUser?.role === 'educator' ? '/educator' : '/child'
  )

  useEffect(() => {
    if (!userFormQuery.isLoading && userFormQuery?.isSuccess && userFormQuery?.data) {
      const postUrl =
        currentUser?.role === 'educator'
          ? `/educator/post-approval/${userFormQuery?.data?._id}`
          : `/child/post-approval/${userFormQuery?.data?._id}`
      const url =
        currentUser?.role === 'educator'
          ? `/educator/edit/${userFormQuery?.data?._id}`
          : `/child/edit/${userFormQuery?.data?._id}`
      if (
        userFormQuery?.data?.status === 'Pending' &&
        window.location.pathname !== '/approval-request'
      ) {
        navigate('/approval-request')

        window.location.href = '/approval-request'
      } else if (userFormQuery?.data?.status === 'InProgress' && window.location.pathname !== url) {
        window.location.href = url
      } else if (
        userFormQuery?.data?.status === 'Approved' &&
        window.location.pathname !== postUrl
      ) {
        if (window.location.pathname !== postUrl) {
          window.location.href = postUrl
        }
      }

      if (userFormQuery?.data?.status === 'Pending') {
        setHomeRoute('/approval-request')
      } else if (userFormQuery?.data?.status === 'InProgress') {
        setHomeRoute(url)
      } else if (userFormQuery?.data?.status === 'Approved') {
        setHomeRoute(postUrl)
      }
    }
  }, [userFormQuery.isLoading])

  return (
    <>
      <Link to={homeRoute} className='d-flex align-items-center'>
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/me2logo.png')} className='h-50px' />

        <h6 className='mb-0 mt-5 ms-2'> Me2 Family Daycare</h6>
      </Link>
    </>
  )
}
