/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import {useAuth} from '../../../../../auth'
import Collapsible from '../../../../common/Collapsible'
import ChildHeader from '../../../../common/ChildFormHeader'

const EducatorFeesAndRelationship: FC<any> = ({values, setFieldValue}) => {
  const {currentUser} = useAuth()

  return (
    <div className='w-100'>
      <ChildHeader />
      <div className='pb-10 pb-lg-15'>
        <div className='shadow-sm'>
          <div className='d-flex flex-column p-9'>
            <div className='card shadow-sm mb-4'>
              <div className='card-body'>
                <p className='card-text'>
                  Fees are payable for the service of caring for and educating a child. Fees are
                  calculated by multiplying the number of hours by the hourly rate. The parent (or
                  the person liable to pay the child care fees) must pay any additional costs and
                  charges incurred in respect of the child. CCS is paid to Me 2 FDC and then Me 2
                  FDC pays the Educator. CCS pays for only 42 absences in a year.
                </p>
                <p className='card-text'>
                  If CCS is reduced or refused, the parent (or the person liable to pay child care
                  fees) must pay for the full amount of the fees. Charges are payable for expenses
                  incurred while providing the service of caring for and educating a child.
                </p>
                <p className='card-text'>
                  Charges might include (but this is not a complete list) money paid to enter places
                  visited on excursion (like the zoo or a museum or the cinema), food, and nappies
                  (where the parent has not supplied enough nappies).
                </p>
                <p className='card-text'>
                  <strong> IMPORTANT NOTES:</strong> 1. You can NOT use another service for the same
                  hours that you have booked with Me 2 FDC as noted on this schedule.
                </p>
              </div>
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5  mb-2'>
                <span className='required'>
                  Signed by the parties as an agreement and to provide the following consent on:
                </span>
                <Field
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  value={new Date()}
                />
              </label>
              <div className='text-danger mt-2'>
                <ErrorMessage name='child.priority' />
              </div>
            </div>

            <Collapsible title={'Relation with Educator'} id='relation-with-educator'>
              <div className='fv-row mb-10'>
                <span className='d-flex align-items-center fs-5  mb-2 row'>
                  <span className='required col-10'>
                    Do you have any relation with the educator?
                  </span>
                  <div className='row col-2'>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2 '
                        name='child.hasRelationWithEducator'
                        value='yes'
                      />
                      Yes
                    </span>
                    <span className='align-items-center fs-5  m-2 col'>
                      <Field
                        type='radio'
                        className='me-2'
                        name='child.hasRelationWithEducator'
                        value='no'
                      />
                      No
                    </span>
                  </div>
                </span>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='child.hasRelationWithEducator' />
                </div>
              </div>
              {values?.child?.hasRelationWithEducator === 'yes' && (
                <>
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5  mb-2 row'>
                      <span className=' col-10'>
                        New rule relating to conditions for continued approval which relate
                        specifically to the provision of care by a family day care educator to
                        relatives. The rule states that: <br />
                        “it is a condition for continued approval in relation to an FDC service that
                        the provider ensures that less than 50% of the children to whom any FDC
                        educator is providing care within any CCS fortnight at the service are
                        related to the FDC educator as:
                        <br /> (a) a niece or nephew; or
                        <br /> (b) a cousin; or <br />
                        (c) a grandchild (including a great-grandchild).”
                      </span>
                    </label>
                  </div>
                  <div className='fv-row mb-10'>
                    I have read and understood above regulatory requirements and declare that the
                    educator /partner
                    <div className='row'>
                      <div className='col-4 d-flex align-items-center'>
                        <Field
                          type='text'
                          className='form-control form-control-sm form-control-solid'
                          placeholder='Educator name'
                          // name='child.educator.fullName'
                          value={currentUser?.educatorName}
                          disabled
                        />
                      </div>
                      <div className='col-4 d-flex align-items-center'>
                        <div className='col-2'>
                          {' '}
                          <span>is my</span>
                        </div>
                        <div className='col-10'>
                          <Field
                            type='text'
                            className=' col-6 form-control form-control-sm form-control-solid'
                            placeholder='Educator'
                            name='child.relationWithEducator'
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col-4 d-flex align-items-center'>
                        <div className='col-4'>
                          {' '}
                          <span>and there by my child</span>
                        </div>
                        <div className='col-8'>
                          <Field
                            type='text'
                            className='form-control form-control-sm form-control-solid'
                            placeholder='Child Name'
                            name='child.fullName'
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5  mb-2 row'>
                      <span className='required col-10'>Select Relation</span>
                    </label>
                    <Field
                      as='select'
                      name='child.relationWithEducator'
                      className='form-select form-select-solid'
                    >
                      <option value=''>Select Relation</option>
                      <option value='Cousin'>Cousin</option>
                      <option value='German'>Niece / Nephew</option>
                      <option value='Grand Child'>Grand Child</option>
                      <option value='Other'>Other</option>
                    </Field>

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='child.relationWithEducator' />
                    </div>
                  </div>
                </>
              )}
            </Collapsible>
          </div>
        </div>
      </div>
    </div>
  )
}

export {EducatorFeesAndRelationship}
