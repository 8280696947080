import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {ApplicationForm, ICreateEducator, User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const CHILD_URL = `${API_URL}/child`
const GET_USERS_URL = `${API_URL}/users`

const createChild = (formInfo: ICreateEducator): Promise<ICreateEducator | undefined> => {
  return axios
    .post(`${CHILD_URL}`, formInfo)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const updateChild = (formInfo: ICreateEducator): Promise<ICreateEducator | undefined> => {
  return axios
    .patch(`${CHILD_URL}/${formInfo._id}`, formInfo.values)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const getChildByID = (id: string): Promise<ICreateEducator | undefined> => {
  return axios
    .get(`${CHILD_URL}/${id}`)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

const getChildForm = (): Promise<ICreateEducator | undefined> => {
  return axios
    .get(`${CHILD_URL}/get-child-form`)
    .then((response: AxiosResponse<Response<ICreateEducator>>) => response.data)
    .then((response: Response<ICreateEducator>) => response.data)
}

export {createChild, getChildByID, updateChild, getChildForm}
