/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ErrorMessage, FieldArray} from 'formik'
import {useAuth} from '../../../../../auth'
import ChildHeader from '../../../../common/ChildFormHeader'
import {NomineeForm} from '../../../../common/NomineeForm'

const ChildNominees: FC<any> = ({values, setFieldValue}) => {
  const {currentUser} = useAuth()

  return (
    <div className='w-100'>
      <ChildHeader />
      <div className='pb-10 pb-lg-15'>
        <div className='shadow-sm'>
          <div className='d-flex flex-column p-9'>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5  mb-2'>
                <span className='fw-bold'>Other persons nominated by parents to:</span>
              </label>
              <ul>
                <li>
                  Collect and care for the child in the case of accident, injury, trauma or illness.
                </li>
                <li>Authorize the administration of medication</li>
                <li>Authorize taking child outside of the family day care home</li>
                <li>
                  Be notified of an emergency involving the child when the parents or guardians
                  cannot.
                </li>
              </ul>
            </div>

            <FieldArray
              name='nominees'
              render={(arrayHelpers) => (
                <NomineeForm
                  values={values?.nominees}
                  arrayHelpers={arrayHelpers}
                  field='nominees'
                  setFieldValue={setFieldValue}
                />
              )}
            />
            {values?.nominees?.length < 2 && (
              <div className='text-danger mt-2'> Child must have at least 2 nominees</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {ChildNominees}
