import React from 'react'
import {ErrorMessage, Field} from 'formik'
import {AddressDetailInfo} from './AddressDetailInfo'
import {
  countriesList,
  languagesSpoken,
} from '../child-enrolment/child-form/core/ChildEnrolmentHelpers'
import Select from 'react-select'
import DatePicker from './DatePicker'

export const ParentsDetailInfo = ({values, setFieldValue, field, mandatory = true}: any) => {
  return (
    <>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>Full name</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`${field}.fullName`}
              placeholder='Full name'
              disabled={field !== 'secondParent'}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.fullName`} />
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>Date of birth</span>
            </label>
            <DatePicker
              name={`${field}.dob`}
              selected={(values?.dob && new Date(values?.dob)) || null}
              placeholderText='Date of birth'
              onChange={(value: any) => setFieldValue(`${field}.dob`, value)}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.dob`} />
            </div>
          </div>
        </div>
      </div>
      <AddressDetailInfo
        values={values}
        setFieldValue={setFieldValue}
        field={`${field}.address`}
        mandatory={mandatory}
      />
      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span>Home phone</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`${field}.homeContact`}
              placeholder='Home phone'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.homeContact`} />
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>Mobile phone</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`${field}.mobileContact`}
              placeholder='Mobile phone'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.mobileContact`} />
            </div>
          </div>
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>Email address</span>
            </label>

            <Field
              type='email'
              className='form-control form-control-lg form-control-solid'
              name={`${field}.email`}
              placeholder='Email '
              disabled
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.email`} />
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>Language spoken</span>
            </label>
            <Select
              options={languagesSpoken}
              placeholder='Select'
              name={`${field}.languageSpoken`}
              className='multi-select-container mt-3'
              classNamePrefix='multi-select'
              onChange={(e: any) => {
                setFieldValue(`${field}.languageSpoken`, e.value)
              }}
              value={languagesSpoken.find(
                (language: any) => values?.languageSpoken === language.value
              )}
              closeMenuOnSelect={true}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.languageSpoken`} />
            </div>
          </div>
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>Cultural background</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`${field}.culturalBackground`}
              placeholder='Cultural background '
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.culturalBackground`} />
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>Job</span>
            </label>
            <Field as='select' name={`${field}.job`} className='form-select form-select-solid'>
              <option>Select Job</option>
              <option value='full time'>Full time</option>
              <option value='part time'>Part time </option>
              <option value='casual'>Casual</option>
              <option value='N/A'>N/A</option>
            </Field>

            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.job`} />
            </div>
          </div>
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>Country of birth</span>
            </label>
            <Select
              options={countriesList}
              placeholder='Select'
              name={`${field}.birthPlace`}
              className='multi-select-container mt-3'
              classNamePrefix='multi-select'
              onChange={(e: any) => {
                setFieldValue(`${field}.birthPlace`, e.value)
              }}
              value={countriesList.find(
                (country: {value: string}) => values?.birthPlace === country.value
              )}
              closeMenuOnSelect={true}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.birthPlace`} />
            </div>
          </div>

          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>CRN number</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`${field}.CRN`}
              placeholder='CRN'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.CRN`} />
            </div>
          </div>
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className={`${mandatory ? 'required' : ''}`}>Medicare number</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`${field}.medicareNumber`}
              placeholder='Medicare number '
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`${field}.medicareNumber`} />
            </div>
          </div>
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5  mb-2'>
          <span className={`${mandatory ? 'required' : ''}`}>
            Does the child live with the mother?
          </span>
        </label>
        <div className='row'>
          <span className='align-items-center fs-5  m-2 col-4 '>
            <Field
              type='radio'
              className='me-2'
              name={`${field}.childLiveWithMother`}
              value='yes'
            />
            Yes
          </span>
          <span className='align-items-center fs-5  m-2 col-4 '>
            <Field type='radio' className='me-2' name={`${field}.childLiveWithMother`} value='no' />
            No
          </span>
        </div>

        <div className='text-danger mt-2'>
          <ErrorMessage name={`${field}.childLiveWithMother`} />
        </div>
      </div>
    </>
  )
}
