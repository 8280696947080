import React, {useState} from 'react'
import {useAuth} from '../../auth'

const ChildHeader = () => {
  const {currentUser} = useAuth()
  return (
    <>
      <div className='pb-10 pb-lg-15'>
        <div className='shadow-sm'>
          <div className='d-flex flex-column p-9'>
            <h4 className='fw-bolder d-flex align-items-center text-dark'>Privacy Notification</h4>
            <div className='text-gray-600 fs-6'>
              Me 2 FDC uses the enrolment form to collect personal information for the purposes of
              program enrolment and statistical recording. The information may be shared with
              funding agencies and administrators for operational purposes only. The information
              will not be disclosed to any other party unless necessary for the care or education of
              the child, to manage medical treatment, or as required by law. You can amend or
              correct information on request by contacting the ME 2 FDC Coordinator. A copy of this
              enrolment form is kept by Me 2 FDC and by the Educator
            </div>
          </div>
        </div>
      </div>

      <div className='pb-10 pb-lg-15'>
        <div className='shadow-sm'>
          <div className='d-flex flex-column p-9'>
            <div className='row'>
              <div className='col-md-6 '>
                <div className='d-block'>
                  <span className='fw-bold '>Mirza Group PTY LTD</span>
                </div>
                <div className='d-block'>
                  <span className='fw-bold pe-2'>Contact Name:</span>
                  <span className='text-gray-600'>Sara Zafar</span>
                </div>
                <div className='d-block'>
                  <span className='fw-bold pe-2'>Contact no.</span>
                  <span className='text-gray-600'>0435478971</span>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='d-block'>
                  <span className='fw-bold pe-2'>Service ID:</span>
                  <span className='text-gray-600'>190014717K</span>
                </div>
                <div className='d-block'>
                  <span className='fw-bold pe-2'>Address:</span>
                  <span className='text-gray-600'>Suite II, Level I, 67 Sydney Road, Coburg</span>
                </div>
                <div className='d-block'>
                  <span className='fw-bold pe-2'>Email:</span>
                  <span className='text-gray-600'>me2fdc@gmail.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='pb-10 pb-lg-15'>
        <div className='text-gray-800 fs-6'>
          The information collected in this form is required by the Education and Care Services
          National Regulation,r160. Questions marked without an asterisk (*) are not required by the
          Regulations, but you are encouraged to answer these to help us to care for your child
        </div>
      </div>
      <div className='pb-10 pb-lg-15'>
        <div className='shadow-sm'>
          <div className='d-flex flex-column p-9'>
            <div className='fv-row'>
              <div className='row'>
                <div className='col-md-6 fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span>Arrangement date</span>
                  </label>

                  <input
                    name='basicInformation.dob'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Arrangement date'
                    disabled
                  ></input>
                </div>

                <div className='col-md-6 fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span>Educator name</span>
                  </label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='basicInformation.educatorName'
                    placeholder='Educator name'
                    value={currentUser?.educatorName}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChildHeader
